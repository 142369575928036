import { Link } from 'react-router-dom';


function NavProfileDropdown(props) {
    return(
        <div className="dropdown-menu dropdown-menu-end">
            <Link className="navlink dropdown-item" to={`/accounts/${props.id}/`}>My Profile</Link>
            <Link className="navlink dropdown-item" to={`/accounts/${props.id}/create/`}>Edit Profile</Link>
            <Link className="navlink dropdown-item" id="logout-item" to={`/logout/`}>Logout</Link>
        </div>
    );
}


export default NavProfileDropdown;