import { React, useState } from 'react';
import { useNavigate } from "react-router-dom";
import axiosInstance from './Axios';
import Modal from 'react-bootstrap/Modal';
import Signup from '../Authentication/Signup';


function Login() {
    const initialFormData = Object.freeze({
        email: "",
        password: "",
    });


    const [formData, setFormData] = useState(initialFormData);
    const [showError, setShowError] = useState(false);
    const [showSignupPopup, setShowSignupPopup] = useState(false);
    const navigate = useNavigate();


    let accountsHost = "http://localhost:8100";
    if (process.env.hasOwnProperty('REACT_APP_ACCOUNTS_SERVICE_HOST')) {
        accountsHost = process.env.REACT_APP_ACCOUNTS_SERVICE_HOST;
    }


    const handleChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value.trim(),
        });
    }


    const fetchId = email => {
        axiosInstance.get(`${accountsHost}/api/user/${email}/`)
        .then(response => {
            localStorage.setItem('id', response.data.account_id);
        })
        .catch(error => {
            if (error.response) {
                alert("There was an error fetching user by email.");
            }
        });
    }


    const handleSubmit = event => {
        event.preventDefault();
        axiosInstance.post(`${accountsHost}/api/token/`, {
            email: formData.email,
            password: formData.password,
        })
            .then(response => {
                localStorage.setItem('access_token', response.data.access);
                localStorage.setItem('refresh_token', response.data.refresh);
                axiosInstance.defaults.headers['Authorization'] = 
                    'JWT ' + localStorage.getItem('access_token');
            })
                .then(() => {
                    fetchId(formData.email);
                })
                    .then(() => {
                        setTimeout(() => {
                            navigate('/')
                            window.location.reload(false);
                        }, 200);
                    })
            .catch(error => {
                console.log("login: ", error);
                setShowError(true);
            });
    }


    const toggleSignupPopup = () => {
        setShowSignupPopup(!showSignupPopup)
    }


    return (
        <>
            <Modal show={showSignupPopup} onHide={toggleSignupPopup}>
                <div className="modal-styling">
                    <Modal.Header closeButton>
                        <Modal.Title className="auth-header">Signup</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Signup />
                    </Modal.Body>
                </div>
            </Modal>
            <div className="row">
                <div className="offset-1 col-10">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="email">Email</label>
                            <input onChange={handleChange} value={formData.email} required type="email" name="email" id="email" className="form-control form-input"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password">Password</label>
                            <input onChange={handleChange} value={formData.password} required type="password" name="password" id="password" className="form-control form-input"/>
                        </div>
                        <p className="login-signup-p">Don't have an account? <span className="login-signup-cta" onClick={() => toggleSignupPopup()}>Signup here</span>.</p>
                        {showError
                            ? <div className="alert alert-danger error-alert shadow-sm" role="alert">
                                Incorrect email or password.  Having issues?  Feel free to <a href="https://7x5u634seqx.typeform.com/to/Aag0v7MF" target="_blank" rel="noopener noreferrer"  className="alert-link" id="error-alert-link">contact support</a>.
                            </div>
                            : null
                        }
                        <button className="btn btn-primary">Login</button>
                    </form>
                </div>
            </div>
        </>
    );
}


export default Login;