import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AccountFollowMetrics from './AccountFollowMetrics';


function AccountFollowFromProfile(props) {
    const [finishedLoading, setFinishedLoading] = useState(false);


    useEffect(() => {
        setTimeout(() => {
            setFinishedLoading(true);
        }, 300);
    }, []);


    return (
        <>
            {finishedLoading
                ? <>
                    {props.mobile === true
                        ? <button onClick={props.updateUserHasFollowed()} type="button" className="btn btn-primary follow-button-mobile" id={props.followClickCount >= 2 ? "disable-follow" : null}>
                            {props.userHasFollowed ? "Following" : "Follow"}
                        </button>
                        : <div className="follow-button-div">
                            {props.currentUsersAccount 
                                ? <Link type="button" className="btn btn-primary follow-button" to={"create/"}>
                                    Edit Profile
                                </Link> 
                                : <button onClick={props.updateUserHasFollowed()} type="button" className="btn btn-primary follow-button" id={props.followClickCount >= 2 ? "disable-follow" : null}>
                                    {props.userHasFollowed ? "Following" : "Follow"}
                                </button>
                            }
                            <AccountFollowMetrics
                                userHasFollowed={props.userHasFollowed}
                                accountsHostUrl={props.accountsHostUrl}
                                reactHostUrl={props.reactHostUrl}
                            />
                        </div>
                    }
                </>
                : null
            }
        </>
    )
}


export default AccountFollowFromProfile;