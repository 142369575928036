import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Signup from '../Authentication/Signup';


function About() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [showSignupPopup, setShowSignupPopup] = useState(false);


    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token == null) {
            setIsAuthenticated(false);
        } else {
            setIsAuthenticated(true);
        }
    }, []);


    const toggleSignupPopup = () => {
        setShowSignupPopup(!showSignupPopup)
    }


    return(
        <>
        <Modal show={showSignupPopup} onHide={toggleSignupPopup}>
            <div className="modal-styling">
                <Modal.Header closeButton>
                    <Modal.Title className="auth-header">Signup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Signup />
                </Modal.Body>
            </div>
        </Modal>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="container">
            <br />
            <p className="mission-title">Our Mission</p>
            <div className="sub-container-no-border mission-body shadow-sm">
                <p className="about-p-bold">We're here to inspire and connect the builders of tomorrow, so that anyone with a dream to build something greater can engineer a life they love.</p>
            </div>
            <br />
            <br />
            <div>
                <div className="sub-container-no-border about-body">
                    <p className="about-p">If you're here, chances are <em>you</em> are working toward building something greater.  Whether you're still assessing if joining a coding bootcamp is right for you or have already leveraged your experience at a coding bootcamp to land your first job as a software engineer, CodingBootChamp is a community of builders you can lean on throughout your journey.
                    <br />
                    <br />
                    Like writing code, there's no template to becoming a successful software engineer, but there are resources to inspire and to guide you.  CodingBootChamp aims to be one of those resources.  An environment for dreamers and builders alike to share knowledge, advice, accomplishments, and vulnerabilities starting with the first line of code you write.
                    </p>
                </div>
                <br />
                <br />
                <div className="sub-container-no-border about-cta-div shadow-sm">
                    <p className="about-cta-body">Let us join you on your journey.  We can't wait to see what you build!</p>
                    <div className={isAuthenticated ? "about-cta-button-div-auth d-flex" : "about-cta-button-div-no-auth d-flex"}>
                        {isAuthenticated
                            ? null
                            : <button type="button" className="btn btn-primary about-cta-button" onClick={() => toggleSignupPopup()}>
                                Join Today
                            </button>
                        }
                        <a href="https://7x5u634seqx.typeform.com/to/Aag0v7MF" target="_blank" rel="noopener noreferrer" type="button" className="btn btn-primary about-cta-button">
                            Get in Touch
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        </>
    );
}


export default About;