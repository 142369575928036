import React from 'react';


class ProjectDescription extends React.Component {
    render() {
        return(
            <div className="sub-container">
                <div className="containter-fluid">
                    <div className="row">
                        <div className="col-sm">
                            <h2 className="sub-title">Description</h2>
                        </div>
                    </div>
                </div>
                <p className="p-font">{this.props.project.description}</p>
            </div>
        );
    }
}


export default ProjectDescription;