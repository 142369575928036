import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../Authentication/Axios';
import AccountProfile from './AccountProfile';


function AccountMain() {
    const { id } = useParams();

    const [account, setAccount] = useState("");


    let accountsHost = "http://localhost:8100";
    if (process.env.hasOwnProperty('REACT_APP_ACCOUNTS_SERVICE_HOST')) {
        accountsHost = process.env.REACT_APP_ACCOUNTS_SERVICE_HOST;
    }


    let projectsHost = "http://localhost:8090";
    if (process.env.hasOwnProperty('REACT_APP_PROJECTS_SERVICE_HOST')) {
        projectsHost = process.env.REACT_APP_PROJECTS_SERVICE_HOST;
    }


    let reactHost = "http://localhost:3000";
    if (process.env.hasOwnProperty('REACT_APP_REACT_HOST')) {
        reactHost = process.env.REACT_APP_REACT_HOST;
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response =  await axiosInstance.get(`${accountsHost}/api/accounts/${id}/`)
                const data = response.data.account;
                setAccount(data);
            } catch (error) {
                console.log("There was an error loading account", error)
            }
        }
        fetchData();
    }, [id, accountsHost]);


    return (
        <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <AccountProfile
                account={account}
                id={id}
                accountsHostUrl={accountsHost}
                projectsHostUrl={projectsHost}
                reactHostUrl={reactHost}
            />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    );
}


export default AccountMain;