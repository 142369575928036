import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';


function AccountFollowMetrics(props) {
    const { id } = useParams();

    const [followees, setFollowees] = useState([])
    const [followers, setFollowers] = useState([])
    const [userHasFollowed, setUserHasFollowed] = useState(false);
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [followerPopupIsOpen, setFollowerPopupIsOpen] = useState(false);
    const [followeePopupIsOpen, setFolloweePopupIsOpen] = useState(false);
    const imageBaseUrl = "https://coding-boot-champ-assets.s3.amazonaws.com/media/";


    useEffect(() => {
        const fetchData = async () => {
            try {
                const followeesResponse =  await fetch(`${props.accountsHostUrl}/api/followees/${id}/`)
                const followeeData = await followeesResponse.json();
                setFollowees(followeeData.followees);
            } catch (error) {
                console.log("There was an error loading followees", error)
            }
            try {
                const followersResponse =  await fetch(`${props.accountsHostUrl}/api/followers/${id}/`)
                const followerData = await followersResponse.json();
                const followerArray = [];
                followerData.followers.forEach(follower => {
                    followerArray.push(follower.follower.id);
                });
                if (followerArray.includes(parseInt(props.accountId))) {
                    setUserHasFollowed(true);
                }
                setFollowers(followerData.followers);
            } catch (error) {
                console.log("There was an error loading followers", error)
            }
        }
        fetchData();
        setFinishedLoading(true);
    }, [
        id,
        props.accountId,
        userHasFollowed,
        props.userHasFollowed,
        props.accountsHostUrl,
    ]);


    const toggleFollowerPopup = () => {
        setFollowerPopupIsOpen(!followerPopupIsOpen);
    }


    const toggleFolloweePopup = () => {
        setFolloweePopupIsOpen(!followeePopupIsOpen);
    }


    const redirect = id => {
        window.location.href = `${props.reactHostUrl}/accounts/${id}/`;
    }


    return (
        <>
            <Modal show={followerPopupIsOpen} onHide={toggleFollowerPopup}>
                <div className="follow-modal-styling">
                    <Modal.Header closeButton>
                        <Modal.Title className="auth-header">Followers</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="follow-modal-body">
                        {followers.map(follower => {
                            return (
                                <div key={follower.id} className="follow-line-item d-flex shadow-sm" onClick={() => redirect(follower.follower.id)}>
                                    {follower.follower.profile_picture
                                        ? <img id="follow-profile-picture" src={`${imageBaseUrl}${follower.follower.profile_picture}`} alt={`${follower.follower.user.first_name} ${follower.follower.user.last_name}`}/>
                                        : <svg xmlns="http://www.w3.org/2000/svg" fill="#b4b4b3" className="bi bi-person-circle" id="follow-profile-picture" viewBox="0 0 16 16">
                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                            <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                        </svg>
                                    }
                                    <p id="follow-name">{`${follower.follower.user.first_name} ${follower.follower.user.last_name}`}</p>
                                </div>
                            );
                        })}
                    </Modal.Body>
                </div>
            </Modal>
            <Modal show={followeePopupIsOpen} onHide={toggleFolloweePopup}>
                <div className="follow-modal-styling">
                    <Modal.Header closeButton>
                        <Modal.Title className="auth-header">Following</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="follow-modal-body">
                        {followees.map(followee => {
                            return (
                                <div key={followee.id} className="follow-line-item d-flex shadow-sm" onClick={() => redirect(followee.followee.id)}>
                                    {followee.followee.profile_picture
                                        ? <img id="follow-profile-picture" src={`${imageBaseUrl}${followee.followee.profile_picture}`} alt={`${followee.followee.user.first_name} ${followee.followee.user.last_name}`}/>
                                        : <svg xmlns="http://www.w3.org/2000/svg" fill="#b4b4b3" className="bi bi-person-circle" id="follow-profile-picture" viewBox="0 0 16 16">
                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                            <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                        </svg>
                                    }
                                    <p id="follow-name">{`${followee.followee.user.first_name} ${followee.followee.user.last_name}`}</p>
                                </div>
                            );
                        })}
                    </Modal.Body>
                </div>
            </Modal>
            {finishedLoading
                ? <>
                    <div className="d-flex">
                        <div className="follow-div">
                            {followers.length > 0
                            ? <>
                                <p onClick={() => toggleFollowerPopup()} id="follow-count-pointer">{followers.length}</p>
                                <p onClick={() => toggleFollowerPopup()} id="follow-pointer">Followers</p>
                            </>
                            : <>
                                <p id="follow-count">{followers.length}</p>
                                <p>Followers</p>
                            </>
                            }
                        </div>
                        <div className="follow-div">
                            {followees.length > 0
                            ? <>
                                <p onClick={() => toggleFolloweePopup()} id="follow-count-pointer">{followees.length}</p>
                                <p onClick={() => toggleFolloweePopup()} id="follow-pointer">Following</p>
                            </>
                            : <>
                                <p id="follow-count">{followees.length}</p>
                                <p>Following</p>
                            </>
                            }
                        </div>
                    </div>
                </>
                : null
            }
        </>
    )
}


export default AccountFollowMetrics;