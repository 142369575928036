import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom';
// import Home from './Home';
import Login from '../Authentication/Login';
import Logout from '../Authentication/Logout';
import Signup from '../Authentication/Signup';
import About from './About';
import AccountMain from '../Account/AccountMain';
import AccountPreForm from '../Account/AccountPreForm';
import ProjectsList from '../Project/ProjectsList';
import ProjectMain from '../Project/ProjectMain';
import ProjectPreForm from '../Project/ProjectPreForm';
import BootcampMain from '../Bootcamp/BootcampMain';
import { AnimatePresence } from 'framer-motion';


function AnimatedRoutes() {
    const location = useLocation();
    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                {/* <Route path="/" element={<Home />} /> */}
                <Route path="/" element={<ProjectsList />} />
                <Route path="/login/" element={<Login />} />
                <Route path="/logout/" element={<Logout />} />
                <Route path="/signup/" element={<Signup />} />
                <Route path="/about/" element={<About />} />
                <Route path="/accounts/:id/" element={<AccountMain />} />
                <Route path="/accounts/:id/create/" element={<AccountPreForm />} />
                <Route path="/projects/" element={<ProjectsList />} />
                <Route path="/projects/:id/" element={<ProjectMain />} />
                <Route path="/projects/:id/create/" element={<ProjectPreForm />} />
                <Route path="/bootcamps/:id/" element={<BootcampMain />} />
            </Routes>
        </AnimatePresence>
    );
}


export default AnimatedRoutes;