import { React, useEffect, useState } from 'react';


function BootcampMetrics(props) {
    const [projectsCount, setProjectsCount] = useState(0);
    const [upvoteCount, setUpvoteCount] = useState(0);
    const [alumniCount, setAlumniCount] = useState(0);
    const [finishedLoading, setFinishedLoading] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            const projectsResponse = await fetch(`${props.projectsHostUrl}/api/projects/`);
            if (projectsResponse.ok) {
                const data = await projectsResponse.json();
                const projectsArray = [];
                let upvoteCount = 0;
                data.projects.forEach(project => {
                    if (project.discoverable === true && project.program.bootcamp.name === props.bootcamp.name) {
                        projectsArray.push(project);
                        upvoteCount += project.upvote_count;
                    }
                });
                setProjectsCount(projectsArray.length);
                setUpvoteCount(upvoteCount);
            } else {
                console.log("There was an error fetching number of projects: ", projectsResponse);
            }

            const accountsResponse = await fetch(`${props.accountsHostUrl}/api/accounts/`);
            if (accountsResponse.ok) {
                const data = await accountsResponse.json();
                const filteredAccounts = [];
                data.accounts.forEach(account => {
                    if (account.bootcamp != null) {
                        if (account.bootcamp.id === props.bootcamp.id && account.discoverable === true) {
                            filteredAccounts.push(account);
                        }
                    }
                });
                setAlumniCount(filteredAccounts.length)
            } else {
                console.log("There was an error fetching number of accounts: ", accountsResponse);
            }
        }
        fetchData();
        setTimeout(() => {
            setFinishedLoading(true);
        }, 100);
    }, [
        props.accountsHostUrl,
        props.bootcamp.id,
        props.bootcamp.name,
        props.projectsHostUrl,
    ]);


    return (
        <div className="bootcamp-metrics-div">
            {finishedLoading
                ? <div className="d-flex">
                    <div className="bootcamp-metrics-left-div shadow-sm">
                        <p id="bootcamp-metrics">{projectsCount}</p>
                        <p>Projects</p>
                    </div>
                    <div className="bootcamp-upvote-div shadow-sm">
                        <p id="bootcamp-upvote-count">{upvoteCount}</p>
                        <p id="bootcamp-upvote-label">Upvotes</p>
                    </div>
                    <div className="bootcamp-metrics-right-div shadow-sm">
                        <p id="bootcamp-metrics">{alumniCount}</p>
                        <p>Alumni</p>
                    </div>
                </div>
                : null
            }
        </div>
    );
}


export default BootcampMetrics;