import { Link } from 'react-router-dom';
import NavBootcampDropdown from './NavBootcampDropown';
import NavCta from './NavCta';


function Nav() {
    let accountsHost = "http://localhost:8100";
    if (process.env.hasOwnProperty('REACT_APP_ACCOUNTS_SERVICE_HOST')) {
        accountsHost = process.env.REACT_APP_ACCOUNTS_SERVICE_HOST;
    }


    let bootcampsHost = "http://localhost:8080";
    if (process.env.hasOwnProperty('REACT_APP_BOOTCAMPS_SERVICE_HOST')) {
        bootcampsHost = process.env.REACT_APP_BOOTCAMPS_SERVICE_HOST;
    }


    return (
        <nav className="navbar navbar-expand-md">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">CodingBootChamp</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <svg xmlns="http://www.w3.org/2000/svg" className="bi-list" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link" id="nav-link" aria-current="page" to={`/projects/`}>Projects</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" id="nav-link" data-bs-toggle="dropdown" aria-current="page" to={`/bootcamps/`}>Bootcamps</Link>
                            <NavBootcampDropdown bootcampsHostUrl={bootcampsHost} />
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" id="nav-link" aria-current="page" to={`/about/`}>About</Link>
                        </li>
                    </ul>
                    <NavCta accountsHostUrl={accountsHost} />
                </div>
            </div>
        </nav>
    );
}


export default Nav;