import React from 'react';
import BootcampAlumni from '../Bootcamp/BootcampAlumni';


class ProjectTeam extends React.Component {
    render() {
        return(
            <div className="sub-container-no-border">
                <div className="containter-fluid">
                    <div className="row">
                        <div className="col-sm">
                            <h2 className="sub-title">Team</h2>
                        </div>
                    </div>
                </div>
                <div  className="team-container">
                    <BootcampAlumni
                        teamMembers={this.props.project.team_members}
                        accountsHostUrl={this.props.accountsHostUrl}
                        projectsHostUrl={this.props.projectsHostUrl}
                        reactHostUrl={this.props.reactHostUrl}
                    />
                </div>
            </div>
        );
    }
}


export default ProjectTeam;