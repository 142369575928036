import { React } from 'react';
import BootcampProjects from '../Bootcamp/BootcampProjects';


function ProjectsList() {
    let projectsHost = "http://localhost:8090";
    if (process.env.hasOwnProperty('REACT_APP_PROJECTS_SERVICE_HOST')) {
        projectsHost = process.env.REACT_APP_PROJECTS_SERVICE_HOST;
    }


    let reactHost = "http://localhost:3000";
    if (process.env.hasOwnProperty('REACT_APP_REACT_HOST')) {
        reactHost = process.env.REACT_APP_REACT_HOST;
    }


    return (
        <>
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="container">
                <BootcampProjects
                    type="list"
                    displayFilters={true}
                    projectsHostUrl={projectsHost}
                    reactHostUrl={reactHost}
                />
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
        </>
    );
}


export default ProjectsList;