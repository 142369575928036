import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BootcampProfile from './BootcampProfile';


function BootcampMain() {
    const { id } = useParams();

    const [bootcamp, setBootcamp] = useState("");


    let accountsHost = "http://localhost:8100";
    if (process.env.hasOwnProperty('REACT_APP_ACCOUNTS_SERVICE_HOST')) {
        accountsHost = process.env.REACT_APP_ACCOUNTS_SERVICE_HOST;
    }


    let bootcampsHost = "http://localhost:8080";
    if (process.env.hasOwnProperty('REACT_APP_BOOTCAMPS_SERVICE_HOST')) {
        bootcampsHost = process.env.REACT_APP_BOOTCAMPS_SERVICE_HOST;
    }


    let projectsHost = "http://localhost:8090";
    if (process.env.hasOwnProperty('REACT_APP_PROJECTS_SERVICE_HOST')) {
        projectsHost = process.env.REACT_APP_PROJECTS_SERVICE_HOST;
    }


    let reactHost = "http://localhost:3000";
    if (process.env.hasOwnProperty('REACT_APP_REACT_HOST')) {
        reactHost = process.env.REACT_APP_REACT_HOST;
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response =  await fetch(`${bootcampsHost}/api/bootcamps/${id}/`)
                const data = await response.json();
                setBootcamp(data);
            } catch (error) {
                console.log("There was an error loading bootcamp", error)
            }
        }
        fetchData();
    }, [id, bootcampsHost]);


    return (
        <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <BootcampProfile
                bootcamp={bootcamp}
                accountsHostUrl={accountsHost}
                bootcampsHostUrl={bootcampsHost}
                projectsHostUrl={projectsHost}
                reactHostUrl={reactHost}
            />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    );
}


export default BootcampMain;
