import React from 'react';
import axiosInstance from '../Authentication/Axios';


class ProjectCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountId: 0,
            title: "",
            teamMembers: [],
            bootcamps: [],
            bootcamp: 0,
            programs: [],
            filteredPrograms: [],
            program: 0,
            graduationDate: "",
            showBootcampField: false,
            showAccountFormError: false,
        }
    }


    async componentDidMount() {
        let bootcampsHost = "http://localhost:8080";
        if (process.env.hasOwnProperty('REACT_APP_BOOTCAMPS_SERVICE_HOST')) {
            bootcampsHost = process.env.REACT_APP_BOOTCAMPS_SERVICE_HOST;
        }

        if (this.props.account.program == null) {
            const bootcampResponse = await fetch(`${bootcampsHost}/api/bootcamps/`);
            if (bootcampResponse.ok) {
                const bootcampData = await bootcampResponse.json();
                this.setState({
                    bootcamps: bootcampData.bootcamps
                });
            } else {
                console.log("There was an error fetching list of bootcamps: ", bootcampResponse);
            }

            const programResponse = await fetch(`${bootcampsHost}/api/programs/`);
            if (programResponse.ok) {
                const programData = await programResponse.json();
                this.setState({
                    programs: programData.programs,
                    filteredPrograms: programData.programs,
                });
            } else {
                console.log("There was an error fetching list of programs: ", programResponse);
            }

            const team = [];
            team.push(this.props.account.id);
            this.setState({
                accountId: this.props.account.id,
                teamMembers: team,
                showBootcampField: true,
            });

        } else {
            const team = [];
            team.push(this.props.account.id);
            this.setState({
                accountId: this.props.account.id,
                teamMembers: team,
                bootcamp: this.props.account.bootcamp.id,
                program: this.props.account.program.id,
            });
        }
    }


    handleChange = event => {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });
    }


    handleBootcampChange = event => {
        const {name, value} = event.target;
        const bootcampPrograms = [];
        if (value === "") {
            this.setState({
                name: 0,
                filteredPrograms: this.state.programs,
            });
        }
        this.state.programs.forEach(program => {
            if (program.bootcamp.id === parseInt(value)) {
                bootcampPrograms.push(program);
            }
        });
        this.setState({
            [name]: value,
            filteredPrograms: bootcampPrograms,
        });
    }


    timeoutRedirect = (response) => {
        let reactHost = "http://localhost:3000";
        if (process.env.hasOwnProperty('REACT_APP_REACT_HOST')) {
            reactHost = process.env.REACT_APP_REACT_HOST;
        }

        const projectId = response.data.id;
        setTimeout(() => {
            window.location.href = `${reactHost}/projects/${projectId}/`;
        }, 300);
    }


    handleSubmit = async event => {
        event.preventDefault();

        if (this.state.title === "") {
            this.setState({
                showAccountFormError: true
            });
            return false;
        }

        if (this.state.showBootcampField === true) {
            if (
                this.state.bootcamp < 1
                || this.state.program < 1
                || this.state.graduationDate === ""
            ) {
                this.setState({
                    showAccountFormError: true
                });
                return false;
            }
        }

        let accountsHost = "http://localhost:8100";
        if (process.env.hasOwnProperty('REACT_APP_ACCOUNTS_SERVICE_HOST')) {
            accountsHost = process.env.REACT_APP_ACCOUNTS_SERVICE_HOST;
        }

        let projectsHost = "http://localhost:8090";
        if (process.env.hasOwnProperty('REACT_APP_PROJECTS_SERVICE_HOST')) {
            projectsHost = process.env.REACT_APP_PROJECTS_SERVICE_HOST;
        }

        if (this.state.showBootcampField === true) {
            const accountData = {
                "bootcamp_id": this.state.bootcamp,
                "program_id": this.state.program,
                "graduation_date": this.state.graduationDate,
            }

            const projectData = {
                "title": this.state.title,
                "program_id": this.state.program,
                "team_members_id": this.state.teamMembers,
                "discoverable": false,
            }

            axiosInstance.put(`${accountsHost}/api/accounts/${this.state.accountId}/`, accountData)
            .catch(error => {
                console.log("accountForm: ", error)
            })

            axiosInstance.post(`${projectsHost}/api/projects/`, projectData)
            .then(response => {
                this.timeoutRedirect(response);
            })
            .catch(error => {
                console.log("projectForm: ", error)
            })

        } else {
            const projectData = {
                "title": this.state.title,
                "program_id": this.state.program,
                "team_members_id": this.state.teamMembers,
                "discoverable": false,
            }

            axiosInstance.post(`${projectsHost}/api/projects/`, projectData)
            .then(response => {
                this.timeoutRedirect(response);
            })
            .catch(error => {
                console.log("projectForm: ", error)
            })
        }
    }


    render() {
        return (
            <div className="row">
                <div className="offset-1 col-10">
                    <form onSubmit={this.handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="title">Give a title to your project.</label>
                            <input onChange={this.handleChange} value={this.state.title} required type="text" name="title" id="title" className="form-control form-input"/>
                        </div>
                        {this.state.showBootcampField
                        ? <>
                            <div className="mb-3">
                                <label htmlFor="bootcamp">Which bootcamp are you/did you attend?</label>
                                <select onChange={this.handleBootcampChange} value={this.state.bootcamp} name="bootcamp" id="bootcamp" className="form-input form-select">
                                    <option value="">Select a Bootcamp</option>
                                    {
                                        this.state.bootcamps.map(bootcamp => {
                                            return <option key={bootcamp.id} value={bootcamp.id}>{bootcamp.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            {this.state.bootcamp === 0 || this.state.bootcamp === ""
                            ? null
                            : <div className="mb-3">
                                <label htmlFor="program">Which program are you/did you participate in?</label>
                                <select onChange={this.handleChange} value={this.state.program} name="program" id="program" className="form-input form-select">
                                    <option value="">Select a Program</option>
                                    {
                                        this.state.filteredPrograms.map(program => {
                                            return <option key={program.id} value={program.id}>{program.name}</option>
                                        })
                                    }
                                </select>
                            </div>}
                            {this.state.bootcamp === 0 || this.state.bootcamp === ""
                            ? null
                            : <div className="mb-3">
                                <label htmlFor="graduationDate">Graduation Date</label>
                                <input onChange={this.handleChange} value={this.state.graduationDate} type="date" name="graduationDate" id="graduationDate" className="form-input form-control"/>
                            </div>}
                        </> : null
                        }
                        {this.state.showAccountFormError
                            ? <div className="alert alert-danger error-alert shadow-sm" role="alert">
                                Please be sure to complete all required fields.
                            </div>
                            : null
                        }
                        <br />
                        <button className="btn btn-primary" onClick={this.props.toggleClose}>Get Started</button>
                    </form>
                </div>
            </div>
        );
    }
}


export default ProjectCreate;