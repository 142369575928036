import { React, useEffect, useState } from 'react';


function Upvote(props) {
    const [accountId, setAccountId] = useState(0);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [upvotes, setUpvotes] = useState([]);
    const [userHasUpvoted, setUserHasUpvoted] = useState(false);
    const [upvoteCount, setUpvoteCount] = useState(0);


    useEffect(() => {
        setAccountId(localStorage.getItem('id'));
        if (accountId == null) {
            setIsAuthenticated(false);
        } else {
            setIsAuthenticated(true);
        }

        const fetchData = async () => {
            try {
                const response =  await fetch(`${props.projectsHostUrl}/api/projects/${props.project.id}/upvotes/`)
                const data = await response.json();
                const upvotes = [];
                const upvoterArray = [];
                let upvotesInt = data.upvotes.length;
                data.upvotes.forEach(upvote => {
                    upvotes.push(upvote);
                    upvoterArray.push(upvote.account.id);
                });
                if (upvoterArray.includes(parseInt(accountId))) {
                    setUserHasUpvoted(true);
                } else {
                    setUserHasUpvoted(false)
                }
                setUpvotes(upvotes);
                setUpvoteCount(upvotesInt);
            } catch (error) {
                console.log("There was an error loading upvotes", error)
            }
        }
        fetchData();
    }, [
        userHasUpvoted,
        upvoteCount,
        accountId,
        props.project.id,
        props.projectsHostUrl,
    ]);


    const handleClick = (projectId) => {
        return async event => {
            if (!(userHasUpvoted)) {
                event.preventDefault();
                const data = {
                    "account": parseInt(accountId),
                    "project": projectId,
                }
                const response = await fetch (`${props.projectsHostUrl}/api/upvotes/`, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    setUserHasUpvoted(true);
                }
            } else {
                event.preventDefault();
                let upvoteId = 0;
                upvotes.forEach(upvote => {
                    if (upvote.account.id === parseInt(accountId)) {
                        upvoteId = upvote.id;
                    }
                });
                const response = await fetch (`${props.projectsHostUrl}/api/upvotes/${upvoteId}/`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    setUserHasUpvoted(false);
                }
            }
        }
    }


    return (
        <>
            <button
                onClick={isAuthenticated ? handleClick(props.project.id, props.project.title) : () => props.toggleLoginPopup()} 
                type="button" 
                className={userHasUpvoted ? "btn btn-primary upvote-button" : "btn btn-secondary upvote-button"}
                id={props.id}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={userHasUpvoted ? "#fff": "#b4b4b3"} className={userHasUpvoted ? "bi bi-caret-up-fill" : "bi bi-caret-up-empty"} viewBox="0 0 16 16">
                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                </svg>
                {upvoteCount === 0 
                    ? 0 
                    : <>
                        {props.id === "upvote-mobile" || props.id === "profile-button-mobile"
                            ? upvoteCount
                            : <div>{upvoteCount}</div>
                        }
                    </>
                }
            </button>
        </>
    )
}


export default Upvote;