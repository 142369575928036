import axios from 'axios';
// import { csrftoken } from '../Core/csrftoken';


let accountsHost = "http://localhost:8100";
    if (process.env.hasOwnProperty('REACT_APP_ACCOUNTS_SERVICE_HOST')) {
        accountsHost = process.env.REACT_APP_ACCOUNTS_SERVICE_HOST;
    }


// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
// axios.defaults.xsrfCookieName = "csrftoken";


const axiosInstance = axios.create({
    timeout: 5000,
	// withCredentials: true,
    headers: {
        Authorization: localStorage.getItem('access_token') 
            ? 'JWT ' + localStorage.getItem('access_token')
            : null,
        'Content-Type': 'application/json',
        'accept': 'application/json',
		// 'X-CSRFTOKEN': csrftoken,
    },
});


axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;

		if (typeof error.response === 'undefined') {
			alert(
				'A server/network error occurred. ' +
					'Sorry about that - still working out the kinks. ' +
						'Refreshing the page once or twice should get you going again.'
			);
			return Promise.reject(error);
		}

		if (error.response.status === 401 && originalRequest.url === `${accountsHost}/api/token/refresh/`) {
			window.location.href = '/login/';
			return Promise.reject(error);
		}

		if (error.response.data.code === 'token_not_valid' && error.response.status === 401 && error.response.statusText === 'Unauthorized') {
			const refreshToken = localStorage.getItem('refresh_token');

			if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

				// Exp date in token is expressed in seconds, while now() returns milliseconds:
				const now = Math.ceil(Date.now() / 1000);
				console.log(tokenParts.exp);

				if (tokenParts.exp > now) {
					return axiosInstance
						.post(`${accountsHost}/api/token/refresh/`, { refresh: refreshToken })
						.then((response) => {
							localStorage.setItem('access_token', response.data.access);
							localStorage.setItem('refresh_token', response.data.refresh);

							axiosInstance.defaults.headers['Authorization'] =
								'JWT ' + response.data.access;
							originalRequest.headers['Authorization'] =
								'JWT ' + response.data.access;

							return axiosInstance(originalRequest);
						})
						.catch((error) => {
							console.log(error);
						});
				} else {
					console.log('Refresh token is expired', tokenParts.exp, now);
					window.location.href = '/login/';
				}
			} else {
				console.log('Refresh token not available.');
				window.location.href = '/login/';
			}
		}

		// Specific error handling done elsewhere.
		return Promise.reject(error);
	}
);


export default axiosInstance;