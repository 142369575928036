import React from 'react';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import axiosInstance from '../Authentication/Axios';


class AccountForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountId: localStorage.getItem('id'),
            accountsHostUrl: "",
            bootcampsHostUrl: "",
            tagsHostUrl: "",
            reactHostUrl: "",
            userId: 0,
            firstName: "",
            lastName: "",
            email: "",
            showProfilePictureField: false,
            profilePicture: "",
            profilePictureName: "",
            profilePicturePath: "",
            profilePictureHasChanged: false,
            headline: "",
            remoteRepositoryUrl: "",
            linkedInUrl: "",
            websiteUrl: "",
            bio: "",
            graduationDate: "",
            discoverable: false,
            bootcamps: [],
            bootcamp: 0,
            programs: [],
            filteredPrograms: [],
            program: 0,
            tags: [],
            selectedTags: [],
            finishedLoading: false,
            showAccountFormError: false,
        }
    }


    async componentDidMount() {
        let accountsHost = "http://localhost:8100";
        if (process.env.hasOwnProperty('REACT_APP_ACCOUNTS_SERVICE_HOST')) {
            accountsHost = process.env.REACT_APP_ACCOUNTS_SERVICE_HOST;
        }

        let bootcampsHost = "http://localhost:8080";
        if (process.env.hasOwnProperty('REACT_APP_BOOTCAMPS_SERVICE_HOST')) {
            bootcampsHost = process.env.REACT_APP_BOOTCAMPS_SERVICE_HOST;
        }

        let tagsHost = "http://localhost:8060";
        if (process.env.hasOwnProperty('REACT_APP_TAGS_SERVICE_HOST')) {
            tagsHost = process.env.REACT_APP_TAGS_SERVICE_HOST;
        }

        let reactHost = "http://localhost:3000";
        if (process.env.hasOwnProperty('REACT_APP_REACT_HOST')) {
            reactHost = process.env.REACT_APP_REACT_HOST;
        }

        this.setState({
            accountsHostUrl: accountsHost,
            bootcampsHostUrl: bootcampsHost,
            tagsHostUrl: tagsHost,
            reactHostUrl: reactHost,
        });

        try {
            const accountResponse = await axiosInstance.get(`${accountsHost}/api/accounts/${this.state.accountId}/`);
            const accountData = accountResponse.data;
            this.setState({
                userId: accountData.account.user.id,
                firstName: accountData.account.user.first_name,
                lastName: accountData.account.user.last_name,
                email: accountData.account.user.email,
            });
            if (accountData.account.profile_picture != null) {
                this.setState({
                    profilePicture: accountData.account.profile_picture,
                    profilePicturePath: `https://coding-boot-champ-assets.s3.amazonaws.com/media/${accountData.account.profile_picture}`,
                });
            }
            if (accountData.account.headline != null) {
                this.setState({
                    headline: accountData.account.headline
                });
            }
            if (accountData.account.remote_repository_url != null) {
                this.setState({
                    remoteRepositoryUrl: accountData.account.remote_repository_url
                });
            }
            if (accountData.account.linkedin_url != null) {
                this.setState({
                    linkedInUrl: accountData.account.linkedin_url
                });
            }
            if (accountData.account.website_url != null) {
                this.setState({
                    websiteUrl: accountData.account.website_url
                });
            }
            if (accountData.account.bio != null) {
                this.setState({
                    bio: accountData.account.bio
                });
            }
            if (accountData.account.graduation_date != null) {
                this.setState({
                    graduationDate: accountData.account.graduation_date
                });
            }
            if (accountData.account.discoverable === true) {
                this.setState({
                    discoverable: true
                });
            }
            if (accountData.account.bootcamp_id != null) {
                this.setState({
                    bootcamp: accountData.account.bootcamp_id
                });
            }
            if (accountData.account.program_id != null) {
                this.setState({
                    program: accountData.account.program_id
                });
            }
        } catch (error) {
            console.log("There was an error loading account", error);
        }

        try {
            const accountTagsResponse = await axiosInstance.get(`${accountsHost}/api/accounts/${this.state.accountId}/tags/`);
            const accountTagsData = accountTagsResponse.data.account_tags.sort((a, b) => (a.order > b.order) ? 1 : -1);
            this.setState({
                selectedTags: accountTagsData
            });
        } catch (error) {
            console.log("There was an error loading account tags", error);
        }

        const tagsResponse = await fetch(`${tagsHost}/api/tags/`);
        if (tagsResponse.ok) {
            const tagsData = await tagsResponse.json();
            const sortedTags = tagsData.tags.sort((a, b) => (a.name > b.name) ? 1 : -1);
            this.setState({
                tags: sortedTags
            });
        } else {
            console.log("There was an error fetching list of tags: ", tagsResponse);
        }
        const bootcampResponse = await fetch(`${bootcampsHost}/api/bootcamps/`);
        if (bootcampResponse.ok) {
            const bootcampData = await bootcampResponse.json();
            this.setState({
                bootcamps: bootcampData.bootcamps
            });
        } else {
            console.log("There was an error fetching list of bootcamps: ", bootcampResponse);
        }
        const programResponse = await fetch(`${bootcampsHost}/api/programs/`);
        if (programResponse.ok) {
            const programData = await programResponse.json();
            this.setState({
                programs: programData.programs,
                filteredPrograms: programData.programs,
            });
        } else {
            console.log("There was an error fetching list of programs: ", programResponse);
        }

        this.finishedLoading();
    }


    finishedLoading = () => {
        setTimeout(() => {
            this.setState({
                finishedLoading: true,
            });
        }, 300);
    }


    redirect() {
        window.location.href = "/projects";
    }


    handleChange = event => {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });
    }


    showProfilePictureField = () => {
        this.setState({
            showProfilePictureField: true,
        });
    }


    handleTagChange = event => {
        const tagId = event.target.value;

        const selectedTags = this.state.selectedTags;
        let lastInOrder = 0;
        let alreadySelected = false;

        selectedTags.forEach(tag => {
            if (tag.order > lastInOrder) {
                lastInOrder = tag.order;
            } 
            if (tag.tag.id === parseInt(tagId)) {
                alreadySelected = true;
            }
        });

        if (!(alreadySelected)) {
            const selectedTagObj = {
                "account_id": this.state.accountId,
                "tag_id": parseInt(tagId),
                "order": lastInOrder + 1,
            }

            axiosInstance.post(`${this.state.accountsHostUrl}/api/accounts/${this.state.accountId}/tags/`, selectedTagObj)
            .catch(error => console.log("post accountTags: ", error))

                .then(() => {
                    axiosInstance.get(`${this.state.accountsHostUrl}/api/accounts/${this.state.accountId}/tags/`)
                    .then(response => {
                        const accountTagsData = response.data.account_tags.sort((a, b) => (a.order > b.order) ? 1 : -1);
                        this.setState({
                            selectedTags: accountTagsData
                        });
                    })
                    .catch(error => console.log("get accountTags: ", error))
                });
        }
    }


    handleTagClick = tag => {
        axiosInstance.delete(`${this.state.accountsHostUrl}/api/accounts/tags/${tag.id}/`)
        .catch(error => console.log("delete accountTags: ", error))

            .then(() => {
                axiosInstance.get(`${this.state.accountsHostUrl}/api/accounts/${this.state.accountId}/tags/`)
                .then(response => {
                    const accountTagsData = response.data.account_tags.sort((a, b) => (a.order > b.order) ? 1 : -1);
                    this.setState({
                        selectedTags: accountTagsData
                    });
                })
                .catch(error => console.log("get accountTags: ", error))
            });
    }


    handleBootcampChange = event => {
        const {name, value} = event.target;
        const bootcampPrograms = [];
        if (value === "") {
            this.setState({
                name: 0,
                filteredPrograms: this.state.programs,
            });
        }
        this.state.programs.forEach(program => {
            if (program.bootcamp.id === parseInt(value)) {
                bootcampPrograms.push(program);
            }
        });
        this.setState({
            [name]: value,
            filteredPrograms: bootcampPrograms,
        });
    }


    setImage = image => {
        this.setState({
            profilePicture: image,
            profilePictureName: this.state.accountId,
            profilePictureHasChanged: true,
        });
    }


    convertImageToURI = image => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsBinaryString(image);
            fileReader.onload = function (e) {
                // Converts binary data to base64
                const base64ImageString = btoa(e.target.result);
                resolve(`data:${image.type};base64,${base64ImageString}`);
            }
            fileReader.onerror = function (e) {
                console.log('Could not convert image to base64. Error: ', e);
                reject();
            }
        })
    }


    saveImage = async (id, dataURI) => {
        const date = Moment(new Date()).format('LLL')
        const data = {
            "profile_picture": dataURI,
            "profile_picture_name": id + "_" + date,
        }

        axiosInstance.put(`${this.state.accountsHostUrl}/api/accounts/${id}/`, data)
            .catch(error => console.log("profilePicture: ", error))
    }


    handleSubmit = async event => {
        event.preventDefault();

        if (
            this.state.firstName === ""
            || this.state.lastName === ""
            || this.state.profilePicture === ""
            || this.state.headline === ""
        ) {
            this.setState({
                showAccountFormError: true
            });
            return false;
        }

        if (this.state.profilePictureHasChanged) {
            this.convertImageToURI(this.state.profilePicture)
            .then(dataURI => {
                this.saveImage(this.state.accountId, dataURI);
            })
            .catch((e) => {
                console.log('Could not upload image: ', e);
            });
        }
        
        const userData = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
        }

        const accountData = {...this.state};
        accountData["user"] = accountData.userId;
        accountData["remote_repository_url"] = accountData.remoteRepositoryUrl;
        accountData["linkedin_url"] = accountData.linkedInUrl;
        accountData["website_url"] = accountData.websiteUrl;
        accountData["tags_id"] = accountData.selectedTagIds;

        if (accountData.bootcamp !== 0) {
            accountData["graduation_date"] = accountData.graduationDate;
            accountData["bootcamp_id"] = accountData.bootcamp;
            accountData["program_id"] = accountData.program;
        }

        if (accountData["headline"] != null && this.state.profilePicture != null) {
            accountData["discoverable"] = true
        } else {
            accountData["discoverable"] = false
        }

        delete accountData.accountId;
        delete accountData.accountsHostUrl;
        delete accountData.bootcampsHostUrl;
        delete accountData.tagsHostUrl;
        delete accountData.reactHostUrl;
        delete accountData.userId;
        delete accountData.firstName;
        delete accountData.lastName;
        delete accountData.email;
        delete accountData.showProfilePictureField;
        delete accountData.profilePicture;
        delete accountData.profilePictureName;
        delete accountData.profilePicturePath
        delete accountData.profilePictureHasChanged;
        delete accountData.remoteRepositoryUrl;
        delete accountData.linkedInUrl;
        delete accountData.websiteUrl;
        delete accountData.graduationDate;
        delete accountData.bootcamp;
        delete accountData.bootcamps;
        delete accountData.program;
        delete accountData.programs;
        delete accountData.filteredPrograms;
        delete accountData.tags;
        delete accountData.selectedTags;
        delete accountData.finishedLoading;
        delete accountData.showAccountFormError;

        axiosInstance.put(`${this.state.accountsHostUrl}/api/user/${this.state.email}/`, userData)
        .catch(error => {
            console.log("userForm: ", error)
            this.setState({
                showAccountFormError: true
            });
        })
        
        .then(() => {
            axiosInstance.put(`${this.state.accountsHostUrl}/api/accounts/${this.state.accountId}/`, accountData)
            .then(() => {
                setTimeout(() => {
                    window.location.href = `${this.state.reactHostUrl}/accounts/${this.state.accountId}/`;
                }, 500);
            })
            .catch(error => {
                console.log("accountForm: ", error);
                this.setState({
                    showAccountFormError: true
                });
            })
        });
    }


    render() {
        return(
            <>
            {this.state.finishedLoading
            ? <>
            {!(this.props.useParamsAccountId === this.state.accountId)
            ? this.redirect()
            : <>
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="container">
                <br />
                <div className="row">
                    <div className="offset-2 col-8">
                        <div className="d-flex">
                            <p className="form-title">Edit Your Profile</p>
                            <Link to={`/accounts/${this.state.accountId}/`} className="form-back-to-profile">(Back to Profile)</Link>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-div">
                                <div className="mb-3">
                                    <p className="form-header">My Details</p>
                                    <label htmlFor="firstName">First Name *</label>
                                    <input onChange={this.handleChange} value={this.state.firstName} type="text" name="firstName" id="firstName" className="form-input form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="lastName">Last Name *</label>
                                    <input onChange={this.handleChange} value={this.state.lastName} type="text" name="lastName" id="lastName" className="form-input form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="profilePicture">Profile Picture *</label>
                                    <p className="instructions">Recommended dimensions: 400 by 400 pixels.</p>
                                    {this.state.showProfilePictureField
                                        ? <input
                                            className="form-input form-control"
                                            type="file"
                                            name="profilePicture"
                                            id="profilePicture"
                                            accept="image/*"
                                            onChange={event => {
                                                this.setImage(event.target.files[0])
                                            }}
                                        />
                                        : <>
                                            {this.state.finishedLoading
                                                ? <>
                                                    {this.state.profilePicture === ""
                                                        ? <div className="icon-field d-flex">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#b4b4b3" className="bi bi-person-circle profile-picture-preview" viewBox="0 0 16 16">
                                                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                                                <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                                            </svg>
                                                            <p onClick={this.showProfilePictureField} className="icon-update">Update Profile Picture</p>
                                                        </div>
                                                        : <div className="icon-field d-flex">
                                                            <img className="profile-picture-preview" src={this.state.profilePicturePath} alt="profile"/>
                                                            <p onClick={this.showProfilePictureField} className="icon-update">Update Profile Picture</p>
                                                        </div>
                                                    }
                                                </>
                                                : null
                                            }
                                        </>
                                    }
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="headline">Headline *</label>
                                    <input onChange={this.handleChange} value={this.state.headline} placeholder="Software Engineering Student at..." type="text" name="headline" id="headline" className="form-input form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="remoteRepositoryUrl">Remote Repo</label>
                                    <p className="instructions">Please include <u>https://</u> in the URL you provide.</p>
                                    <input onChange={this.handleChange} value={this.state.remoteRepositoryUrl} placeholder="https://gitlab.com/username" type="text" name="remoteRepositoryUrl" id="remoteRepositoryUrl" className="form-input form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="linkedInUrl">LinkedIn</label>
                                    <p className="instructions">Please include <u>https://</u> in the URL you provide.</p>
                                    <input onChange={this.handleChange} value={this.state.linkedInUrl} placeholder="https://linkedin.com/in/username" type="text" name="linkedInUrl" id="linkedInUrl" className="form-input form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="websiteUrl">Personal Website</label>
                                    <p className="instructions">Please include <u>https://</u> in the URL you provide.</p>
                                    <input onChange={this.handleChange} value={this.state.websiteUrl} placeholder="https://mywebsite.com" type="text" name="websiteUrl" id="websiteUrl" className="form-input form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="bio">Bio</label>
                                    <textarea rows="5" onChange={this.handleChange} value={this.state.bio} type="text" name="bio" id="bio" className="form-input form-control"/>
                                </div>
                            </div>
                            <div className="form-div">
                                <br />
                                <p className="form-header">Applied Technical Skills</p>
                                <div className="mb-3">
                                    <label htmlFor="tags">Tags</label>
                                    <p className="instructions">Don't see a tag you'd like to add?  Send it to us <a href="https://7x5u634seqx.typeform.com/to/Aag0v7MF" target="_blank" rel="noopener noreferrer" id="error-alert-link">here</a>, and we'll add it to your profile.</p>
                                    <select onChange={this.handleTagChange} value={this.state.tags} multiple={true} name="tags" id="tags" className="form-select tags-select-desktop">
                                        {
                                            this.state.tags.map(tag => {
                                                return <option key={tag.id} value={tag.id}>{tag.name}</option>
                                            })
                                        }
                                    </select>
                                    <select onChange={this.handleTagChange} name="tags" id="tags" className="form-select tags-select-mobile">
                                        {
                                            this.state.tags.map(tag => {
                                                return <option key={tag.id} value={tag.id}>{tag.name}</option>
                                            })
                                        }
                                    </select>
                                    <div className="tags-form">
                                        {this.state.selectedTags.length > 0 ? <p className="instructions-sm">Click tag to remove</p> : null}
                                        {this.state.selectedTags.map(tag => {
                                            return <div key={tag.id} onClick={() => this.handleTagClick(tag)} className="profile-chip" value={tag} id="tag-form-chip">
                                                <p>{tag.tag.name}</p>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="form-div">
                                <br />
                                <p className="form-header">Bootcamp Details</p>
                                <div className="mb-3">
                                    <label htmlFor="bootcamp">Which bootcamp are you/did you attend? *</label>
                                    <select onChange={this.handleBootcampChange} value={this.state.bootcamp} name="bootcamp" id="bootcamp" className="form-input form-select" placeholder="Select a Bootcamp">
                                        <option value={0}>I'm not affiliated with a bootcamp</option>
                                        {
                                            this.state.bootcamps.map(bootcamp => {
                                                return <option key={bootcamp.id} value={bootcamp.id}>{bootcamp.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                {this.state.bootcamp === 0 || this.state.bootcamp === ""
                                ? null
                                : <div className="mb-3">
                                    <label htmlFor="program">Which program are you/did you participate in?</label>
                                    <select onChange={this.handleChange} value={this.state.program} name="program" id="program" className="form-input form-select">
                                        <option value="">Select a Program</option>
                                        {
                                            this.state.filteredPrograms.map(program => {
                                                return <option key={program.id} value={program.id}>{program.name}</option>
                                            })
                                        }
                                    </select>
                                </div>}
                                {this.state.bootcamp === 0 || this.state.bootcamp === ""
                                ? null
                                : <div className="mb-3">
                                    <label htmlFor="graduationDate">Graduation Date</label>
                                    <input onChange={this.handleChange} value={this.state.graduationDate} type="date" name="graduationDate" id="graduationDate" className="form-input form-control"/>
                                </div>}
                                <br />
                            </div>
                            <br />
                            {this.state.showAccountFormError
                                ? <div className="alert alert-danger error-alert shadow-sm" role="alert">
                                    Please be sure to complete all required fields marked by an asterisk.  If you have any questions, please feel free to <a href="https://7x5u634seqx.typeform.com/to/Aag0v7MF" target="_blank" rel="noopener noreferrer"  className="alert-link" id="error-alert-link">contact support</a>.
                                </div>
                                : null
                            }
                            <button className="btn btn-primary">Save</button>
                            <br />
                        </form>
                        <br />
                        <br />
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            </>
            }
            </>
            : null
            }
            </>
        );
    }
}


export default AccountForm;