import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import axiosInstance from '../Authentication/Axios';
import Upvote from './Upvote';
import ProjectElevatorPitch from './ProjectElevatorPitch';
import YouTubeEmbed from './YouTubeEmbed';
import ProjectCarousel from './ProjectCarousel';
import ProjectDescription from './ProjectDescription';
import ProjectTestimonial from './ProjectTestimonial';
import ProjectTeam from './ProjectTeam';
import ProjectNotification from './ProjectNotification';
import Login from '../Authentication/Login';


class ProjectProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountId: localStorage.getItem('id'),
            currentAccount: {},
            isAuthenticated: false,
            remoteRepo: "",
            website: "",
            imageBaseUrl: 'https://coding-boot-champ-assets.s3.amazonaws.com/media/',
            extension: /^https?:\/\//,
            images: [],
            mainImage: "",
            secondaryImages: [],
            testimonials: [],
            isTeamMember: false,
            finishedLoading: false,
            minimumAssetsProvided: false,
            showLoginPopup: false,
        }
    }
    

    async componentDidMount() {
        if (this.state.accountId == null) {
            this.setState({
                isAuthenticated: false,
            });
        } else {
            this.setState({
                isAuthenticated: true,
            });
        }

        if (this.state.accountId != null) {
            axiosInstance.get(`${this.props.accountsHostUrl}/api/accounts/${this.state.accountId}/`)
            .then(response => {
                this.setState({
                    currentAccount: response.data.account
                });
            })
            .catch(error => console.log("currentUsersAccount: ", error))
        }

        const response = await fetch(`${this.props.projectsHostUrl}/api/testimonials/`);
        if (response.ok) {
            const data = await response.json();
            const filteredTestimonials = [];
            data.testimonials.forEach(testimonial => {
                if (testimonial.project.id === this.props.project.id) {
                    filteredTestimonials.push(testimonial);
                }
            });
            this.setState({
                testimonials: filteredTestimonials,
            });
        } else {
            console.log("There was an error fetching list of testimonials: ", response);
        }

        setTimeout(() => {
            this.props.project.team_members.forEach(teamMember => {
                if (teamMember.id === parseInt(this.state.accountId)) {
                    this.setState({
                        isTeamMember: true,
                    });
                }
            });

            const extension = /^https?:\/\//;
            if (this.props.project.remote_repository_url != null) {
                const remoteRepo = this.props.project.remote_repository_url.replace(extension, "");
                this.setState({
                    remoteRepo: remoteRepo,
                });
            }

            if (this.props.project.website_url != null) {
                const website = this.props.project.website_url.replace(extension, "");
                this.setState({
                    website: website,
                });
            }

            this.setState({
                finishedLoading: true,
            });
        }, 200);
        this.getImagesTimeout();
    }


    getImagesTimeout = () => {
        setTimeout( 
            () => {this.getImages()}, 
            100
        );
    }


    getImages = async () => {
        const response = await fetch(`${this.props.projectsHostUrl}/api/projects/${this.props.project.id}/images/`);
        if (response.ok) {
            const data = await response.json();
            if (data.images.length > 0) {
                const currentImages = [];
                data.images.forEach(image => {
                    if (image.is_current === true) {
                        currentImages.push(image);
                    }
                });

                const sortedCurrentImages = currentImages.sort((a, b) => (a.order > b.order) ? 1 : -1);

                this.setState({
                    images: sortedCurrentImages,
                    mainImage: sortedCurrentImages[0].image,
                    secondaryImages: sortedCurrentImages.slice(1),
                });
            }
            setTimeout( 
                () => {this.minimumAssetsProvided();}, 
                100
            );
        } else {
            console.log("There was an error fetching list of images: ", response);
        }
    }


    minimumAssetsProvided = () => {
        if (
            this.props.project.title != null &&
            this.props.project.title !== "" &&
            this.props.project.icon != null &&
            this.props.project.icon !== "" &&
            this.props.project.headline != null &&
            this.props.project.headline !== "" &&
            this.props.project.remote_repository_url != null &&
            this.props.project.remote_repository_url !== "" &&
            this.props.project.launch_date != null &&
            this.props.project.launch_date !== "" &&
            this.props.project.elevator_pitch != null &&
            this.props.project.elevator_pitch !== "" &&
            this.props.project.description != null &&
            this.props.project.description !== "" &&
            this.props.project.program != null &&
            this.props.project.tags.length > 0 &&
            this.props.project.team_members.length > 0 &&
            this.state.images.length > 0
        ) {
            this.setState({
                minimumAssetsProvided: true,
            });
        }
    }


    toggleLoginPopup = () => {
        this.setState({
            showLoginPopup: (!this.state.showLoginPopup),
        });
    }


    redirect() {
        window.location.href = `${this.props.reactHostUrl}/projects`;
    }

    
    render() {
        return(
            <>
            <Modal show={this.state.showLoginPopup} onHide={this.toggleLoginPopup}>
                <div className="modal-styling">
                    <Modal.Header closeButton>
                        <Modal.Title className="auth-header">Login</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Login />
                    </Modal.Body>
                </div>
            </Modal>
            {this.state.finishedLoading
            ? <>
            {!(this.props.project.discoverable) && !(this.state.isTeamMember) && (this.state.currentAccount.is_staff !== true)
            ? this.redirect()
            : <>
                {this.props.project.discoverable
                ? null
                : <div className="container">
                    <ProjectNotification
                        minimumAssetsProvided={this.state.minimumAssetsProvided}
                        project={this.props.project}
                        projectForm={false}
                    />
                </div>
                }
                <div className="container d-flex">
                    <div className="p-2">
                        <div>
                            {this.props.project.icon
                                ? <img className="icon-large" src={`${this.state.imageBaseUrl}${this.props.project.icon}`} alt={`${this.props.project.title} icon`} />
                                : <svg xmlns="http://www.w3.org/2000/svg" fill="#b4b4b3" className="bi bi-code-square icon-large" viewBox="0 0 16 16">
                                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                    <path d="M6.854 4.646a.5.5 0 0 1 0 .708L4.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0zm2.292 0a.5.5 0 0 0 0 .708L11.793 8l-2.647 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708 0z"/>
                                </svg>
                            }
                            {this.state.isTeamMember
                                ? <Link type="button" className="btn btn-primary follow-button-mobile" to={"create/"}>
                                    Edit
                                </Link>
                                : <Upvote 
                                    project={this.props.project}
                                    id={"profile-button-mobile"}
                                    toggleLoginPopup={this.toggleLoginPopup}
                                    projectsHostUrl={this.props.projectsHostUrl}
                                />
                            }
                        </div>
                        <div className="profile-info">
                            <h2 className="header-title">{this.props.project.title}</h2>
                            <p className="sub-title">{this.props.project.headline ? this.props.project.headline : <em>Add a Headline to Your Project</em>}</p>
                            <div className="d-flex profile-tag-div">
                                <div className="profile-chip">
                                    <img src={this.props.project.program.bootcamp.logo} alt={`${this.props.project.program.bootcamp.name} logo`}/>
                                    <p>{this.props.project.program.bootcamp.name}</p>
                                </div>
                                <div className="profile-chip" id="hide-program-name">
                                    <p>{this.props.project.program.name}</p>
                                </div>
                                {this.props.project.launch_date
                                    ? <div className="profile-chip">
                                        <p>{Moment(this.props.project.launch_date).format('MMM, YYYY')}</p>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                        <div className="profile-button-div">
                            {this.state.isTeamMember
                                ? <Link type="button" className="btn btn-primary follow-button" id="edit-project-button" to="create/">
                                    Edit Project
                                </Link>
                                : <Upvote
                                    project={this.props.project}
                                    id={"profile-button"}
                                    toggleLoginPopup={this.toggleLoginPopup}
                                    projectsHostUrl={this.props.projectsHostUrl}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="container d-flex" id="container-tabs">
                    <div className="tab-link-div d-flex">
                        <span 
                            className="nav-link tab-link d-flex"
                            id={this.props.project.remote_repository_url ? null : "dull-icon"}
                            onClick={this.state.isAuthenticated 
                                ? event => {
                                    event.stopPropagation();
                                    window.open(`//${this.props.project.remote_repository_url.replace(this.state.extension, "")}`, "_blank")
                                }
                                : () => this.toggleLoginPopup()
                            }
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={this.props.project.remote_repository_url ? "black" : "rgb(132, 132, 132)"} className="bi bi-github" viewBox="0 0 16 16">
                                <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
                            </svg>
                            <p className="mobile-tab">Remote Repo</p>
                        </span>
                        <span
                            className="nav-link tab-link d-flex"
                            id={this.props.project.website_url ? null : "dull-icon"} onClick={this.state.isAuthenticated 
                                ? event => {
                                    event.stopPropagation();
                                    window.open(`//${this.props.project.website_url.replace(this.state.extension, "")}`, "_blank")
                                }
                                : () => this.toggleLoginPopup()
                            }
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={this.props.project.website_url ? "black" : "rgb(132, 132, 132)"} className="bi bi-link-45deg" viewBox="0 0 16 16">
                                <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                                <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
                            </svg>
                            <p className="mobile-tab">Demo Site</p>
                        </span>
                    </div>
                </div>
                <div className="container d-flex">
                    <ProjectElevatorPitch
                        project={this.props.project}
                        paramsId={this.props.id}
                        projectsHostUrl={this.props.projectsHostUrl}
                    />
                </div>
                {this.state.images.length > 0
                    ? <div className="container d-flex">
                        <ProjectCarousel
                            project={this.props.project}
                            images={this.state.images}
                            mainImage={this.state.mainImage}
                            secondaryImages={this.state.secondaryImages}
                        />
                    </div>
                    : null
                }
                {this.props.project.video_url
                    ? <div className="container d-flex">
                        <YouTubeEmbed embedUrl={this.props.project.video_url} />
                    </div>
                    :null
                }
                {this.props.project.description
                    ? <div className="container d-flex">
                        <ProjectDescription project={this.props.project} />
                    </div>
                    : null
                }
                {this.state.testimonials.length > 0
                    ? <div className="container d-flex">
                        <ProjectTestimonial
                            testimonials={this.state.testimonials}
                            accountsHostUrl={this.props.accountsHostUrl}
                        />
                    </div>
                    : null
                }
                <div className="container d-flex">
                    <ProjectTeam
                        project={this.props.project}
                        accountsHostUrl={this.props.accountsHostUrl}
                        projectsHostUrl={this.props.projectsHostUrl}
                        reactHostUrl={this.props.reactHostUrl}
                    />
                </div>
            </>
            }
            </>
            : null
            }
            </>
        );
    }
}


export default ProjectProfile;