import React from 'react';


class ProjectTestimonial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageBaseUrl: "https://coding-boot-champ-assets.s3.amazonaws.com/media/",
        }
    }


    render() {
        return(
            <div className="sub-container">
                <div className="containter-fluid">
                    <div className="row">
                        <div className="col-sm">
                            <h2 className="sub-title">Testimonials</h2>
                        </div>
                    </div>
                </div>
                {this.props.testimonials.map(testimonial => {
                    return (
                        <div key={testimonial.id}>
                            <div className="testimonial-div d-flex">
                                <div className="quotation-div">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-quote" viewBox="0 0 16 16">
                                        <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"/>
                                    </svg>
                                </div>
                                <p className="p-font">{testimonial.testimonial}</p>
                            </div>
                            <div className="team-member-chip" id="testimonial-author">
                                {testimonial.account.profile_picture
                                    ? <img src={`${this.state.imageBaseUrl}${testimonial.account.profile_picture}`} alt={`${testimonial.account.user.first_name} ${testimonial.account.user.last_name}`}/>
                                    : <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#b4b4b3" className="bi bi-person-circle team-member-no-profile-pic" viewBox="0 0 16 16">
                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                        <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                    </svg>
                                }
                                <p>{`${testimonial.account.user.first_name} ${testimonial.account.user.last_name}`}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}


export default ProjectTestimonial;