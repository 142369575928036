import React from 'react';
import PropTypes from 'prop-types';


const YouTubeEmbed = ({ embedUrl }) => (
    <div className="sub-container">
        <div className="video">
            <iframe
                src={embedUrl}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded YouTube"
            />
        </div>
    </div>
);


YouTubeEmbed.propTypes = {
    embedUrl: PropTypes.string.isRequired
};


export default YouTubeEmbed;