import React from 'react';


function Footer() {
    return (
        <>
            <div className="footer-container">
                <div className="container-fluid footer-div">
                    <p>© 2023 | Personal Project by Alex Kagawa</p>
                </div>
            </div>
        </>
    )
}


export default Footer;