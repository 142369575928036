import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Upvote from '../Project/Upvote';
import Login from '../Authentication/Login';


function BootcampProjects(props) {
    const [projects, setProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [filtered, setFiltered] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const imageBaseUrl = "https://coding-boot-champ-assets.s3.amazonaws.com/media/";


    useEffect(() => {
        const fetchData = async () => {
            if (props.type === "account") {
                setTimeout(() => {
                    setProjects(props.accountProjects);
                }, 200);

            } else if (props.type === "list" && filtered === false) {
                const response = await fetch(`${props.projectsHostUrl}/api/projects/`);
                if (response.ok) {
                    const data = await response.json();

                    let projectsArray = [];
                    data.projects.forEach(project => {
                        if (project.discoverable === true) {
                            projectsArray.push(project);
                        }
                    });

                    projectsArray = projectsArray.sort((a, b) => (a.activation_date < b.activation_date) ? 1 : -1);
                    setProjects(projectsArray)
                
                } else {
                    console.log("There was an error fetching list of projects: ", response);
                }

            } else if (props.type === "bootcamp" && filtered === false) {
                const response = await fetch(`${props.projectsHostUrl}/api/projects/`);
                if (response.ok) {
                    const data = await response.json();

                    let projectsArray = [];
                    data.projects.forEach(project => {
                        if (project.discoverable === true && project.program.bootcamp.name === props.bootcamp.name) {
                            projectsArray.push(project);
                        }
                    });

                    projectsArray = projectsArray.sort((a, b) => (a.activation_date < b.activation_date) ? 1 : -1);
                    setProjects(projectsArray);
                } else {
                    console.log("There was an error fetching list of projects by bootcamp: ", response);
                }
            }
        }
        fetchData();
    }, [
        filteredProjects,
        filtered,
        props.accountProjects,
        props.bootcamp,
        props.projectsHostUrl,
        props.type,
    ]);


    const handleChange = event => {
        const value = event.target.value;
        if (value === "featured") {
            const featuredArray = projects.sort((a, b) => (a.upvote_count < b.upvote_count) ? 1 : -1);
            setFilteredProjects(featuredArray);
            setFiltered(!filtered);
        } else if (value === "newest") {
            const newestArray = projects.sort((a, b) => (a.activation_date < b.activation_date) ? 1 : -1);
            setFilteredProjects(newestArray);
            setFiltered(!filtered);
        }
    }


    const toggleLoginPopup = () => {
        setShowLoginPopup(!showLoginPopup)
    }


    const redirect = id => {
        window.location.href = `${props.reactHostUrl}/projects/${id}/`;
    }

    
    return (
        <>
            <Modal show={showLoginPopup} onHide={toggleLoginPopup}>
                <div className="modal-styling">
                    <Modal.Header closeButton>
                        <Modal.Title className="auth-header">Login</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Login />
                    </Modal.Body>
                </div>
            </Modal>
            {props.displayFilters
                ? <>
                <br />
                <div className="d-flex filter-div">
                    {props.type === "list"
                    ? <p className="sub-header">Discover what tomorrow's developers are hacking on today</p>
                    : <p className="sub-header">{`Check out the latest and greatest coming out of ${props.bootcamp.name}`}</p>
                    }
                    <select className="btn btn-primary filter-dropdown" type="button" data-toggle="dropdown" onChange={handleChange}>
                        <option value="newest">Newest</option>
                        <option value="featured">Featured</option>
                    </select>
                </div>
                <div className="d-flex filter-div">
                    <select className="btn btn-primary filter-dropdown-mobile" type="button" data-toggle="dropdown" onChange={handleChange}>
                        <option value="newest">Newest</option>
                        <option value="featured">Featured</option>
                    </select>
                </div>
                </>
                : null
            }
            <div className="sub-container-no-border">
                {projects.map(project => {
                    return (
                        <div
                            key={project.id}
                            className="anchor anchor-wrap card"
                            onClick={() => redirect(project.id)}
                        >
                            <div className="project-card shadow-sm">
                                {props.currentUsersAccount && project.discoverable === false
                                    ? <div className="alert alert-primary normal-alert" id="project-alert" role="alert">
                                        <strong>This project is in draft-mode.</strong> To make it discoverable, activate it from your <Link to={`/projects/${project.id}/`} className="alert-link" id="alert-link">project's profile page</Link>.
                                    </div>
                                    : null
                                }
                                <div className="project-card-div d-flex">
                                    <div className="project-card-div-mobile">
                                        {project.icon   
                                            ? <img className="icon-small" src={`${imageBaseUrl}${project.icon}`} alt={`${project.title} logo`} />
                                            : <svg xmlns="http://www.w3.org/2000/svg" fill="#b4b4b3" className="bi bi-code-square icon-small" viewBox="0 0 16 16">
                                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                                <path d="M6.854 4.646a.5.5 0 0 1 0 .708L4.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0zm2.292 0a.5.5 0 0 0 0 .708L11.793 8l-2.647 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708 0z"/>
                                            </svg>
                                        }
                                        <div onClick={event => event.stopPropagation()}>
                                            <Upvote
                                                project={project}
                                                id={"upvote-mobile"}
                                                toggleLoginPopup={toggleLoginPopup}
                                                projectsHostUrl={props.projectsHostUrl}
                                            />
                                        </div>
                                    </div>
                                    <div className="project-card-div">
                                        <p className="card-sub-title">{project.title}</p>
                                        <p className="card-p-font">{project.headline}</p>
                                    </div>
                                    <div onClick={event => event.stopPropagation()}>
                                        <Upvote
                                            project={project}
                                            id={"upvote"}
                                            toggleLoginPopup={toggleLoginPopup}
                                            projectsHostUrl={props.projectsHostUrl}
                                        />
                                    </div>
                                </div>
                                <div className="tag-div d-flex">
                                    <div className="chip">
                                        <img src={project.program.bootcamp.logo} alt={`${project.program.bootcamp.name} logo`}/>
                                        <p>{project.program.bootcamp.name}</p>
                                    </div>
                                    <div className="chip" id="hide-program-name">
                                        <p>{project.program.name}</p>
                                    </div>
                                    <div className="chip">
                                        <p>{Moment(project.launch_date).format('MMM, YYYY')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}


export default BootcampProjects;