import { React, useState } from 'react';
import { useNavigate } from "react-router-dom";
import axiosInstance from './Axios';


function Signup() {
    const initialFormData = Object.freeze({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
    });


    const [formData, setFormData] = useState(initialFormData);
    const [showEmailAlreadyExistsError, setEmailAlreadyExistsError] = useState(false);
    const [showInvalidPasswordError, setInvalidPasswordError] = useState(false);
    const navigate = useNavigate();


    let accountsHost = "http://localhost:8100";
    if (process.env.hasOwnProperty('REACT_APP_ACCOUNTS_SERVICE_HOST')) {
        accountsHost = process.env.REACT_APP_ACCOUNTS_SERVICE_HOST;
    }


    const handleChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value.trim(),
        });
    }


    const fetchId = email => {
        axiosInstance.get(`${accountsHost}/api/user/${email}/`)
        .then(response => {
            localStorage.setItem('id', response.data.account_id);
        })
        .catch(error => {
            if (error.response) {
                alert("There was an error fetching user by email.");
            }
        });
    }


    const handleSubmit = event => {
        event.preventDefault();

        if (
            formData.password.length < 8
            // Checks if password has at least one letter
            || !(/[a-zA-Z]/.test(formData.password))
            // Checks if password has at least one number
            || !(/\d/.test(formData.password))
        ) {
            setInvalidPasswordError(true);
            return false;
        }

        axiosInstance.post(`${accountsHost}/api/user/`, {
            first_name: formData.first_name,
            last_name: formData.last_name,
            email: formData.email,
            password: formData.password,
        })

        .then(response => {
            axiosInstance.post(`${accountsHost}/api/accounts/`, {
                user_id: response.data.id,
            })

            .then(() => {
                axiosInstance.post(`${accountsHost}/api/token/`, {
                    email: formData.email,
                    password: formData.password,
                })

                .then(response => {
                    localStorage.setItem('access_token', response.data.access);
                    localStorage.setItem('refresh_token', response.data.refresh);
                    axiosInstance.defaults.headers['Authorization'] = 
                        'JWT ' + localStorage.getItem('access_token');
                })

                    .then(() => {
                        fetchId(formData.email);
                    })

                        .then(() => {
                            setTimeout(() => {
                                navigate('/');
                                window.location.reload(false);
                            }, 200);
                        })

                .catch(error => {
                    if (error.response) {
                        console.log("There was an error storing token. ", error);
                    }
                });
            })

            .catch(error => {
                if (error.response) {
                    console.log("There was an error creating a token. ", error);
                }
            });
        })

        .catch(error => {
            console.log("userForm: ", error);
            setTimeout(() => {
                if (error.response.data.message.email) {
                    if (error.response.data.message.email.includes("user with this email already exists.")) {
                        setEmailAlreadyExistsError(true);
                    }
                }
            }, 500);
        });
    }


    return (
        <div className="row">
            <div className="offset-1 col-10">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="firstName">First Name</label>
                        <input onChange={handleChange} value={formData.first_name} required type="text" name="first_name" id="firstName" className="form-control form-input"/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="lastName">Last Name</label>
                        <input onChange={handleChange} value={formData.last_name} required type="text" name="last_name" id="lastName" className="form-control form-input"/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email">Email</label>
                        <input onChange={handleChange} value={formData.email} required type="email" name="email" id="email" className="form-control form-input"/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password">Password</label>
                        <input onChange={handleChange} value={formData.password} required type="password" name="password" id="password" className="form-control form-input"/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="earlyAccess">Early Access Code</label>
                        <input required type="text" name="early_access" id="earlyAccess" className="form-control form-input"/>
                    </div>
                    {showEmailAlreadyExistsError
                        ? <div className="alert alert-danger error-alert shadow-sm" role="alert">
                            Email already exists.  Having issues?  Feel free to <a href="https://7x5u634seqx.typeform.com/to/Aag0v7MF" target="_blank" rel="noopener noreferrer"  className="alert-link" id="error-alert-link">contact support</a>.
                        </div>
                        : null
                    }
                    {showInvalidPasswordError
                        ? <div className="alert alert-danger error-alert shadow-sm" role="alert">
                            Passwords need to be at least 8 characters long, and contain at least one letter and one number.
                        </div>
                        : null
                    }
                    <button className="btn btn-primary">Sign Up</button>
                </form>
            </div>
        </div>
    );
}


export default Signup;