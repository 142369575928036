import React from 'react';
import Moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import BootcampAlumniFollow from './BootcampAlumniFollow';
import Login from '../Authentication/Login';


class BootcampAlumni extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountId: localStorage.getItem('id'),
            accounts: [],
            imageBaseUrl: "https://coding-boot-champ-assets.s3.amazonaws.com/media/",
            extension: /^https?:\/\//,
            isAuthenticated: false,
            showLoginPopup: false,
        }
    }


    async componentDidMount() {
        if (this.state.accountId == null) {
            this.setState({
                isAuthenticated: false,
            });
        } else {
            this.setState({
                isAuthenticated: true,
            });
        }

        if (this.props.teamMembers === "null") {
            const response = await fetch(`${this.props.accountsHostUrl}/api/accounts/`);
            if (response.ok) {
                const data = await response.json();
                const filteredAccounts = [];
                const finalAccount = {
                    id: null
                };
                data.accounts.forEach(account => {
                    if (account.bootcamp != null) {
                        if (account.bootcamp.id === this.props.bootcamp.id && account.discoverable === true) {
                            filteredAccounts.push(account);
                        }
                    }
                });

                const sortedAccounts = filteredAccounts.sort((a, b) => (a.created_at < b.created_at) ? 1 : -1);

                const length = sortedAccounts.length;
                if (length % 2 === 1) {
                    sortedAccounts.push(finalAccount);
                }
                this.setState({
                    accounts: sortedAccounts,
                });
            } else {
                console.log("There was an error fetching list of accounts: ", response);
            }
        } else {
            const finalAccount = {
                id: null
            }; 
            const length = this.props.teamMembers.length;
            if (length % 2 === 1) {
                this.props.teamMembers.push(finalAccount);
            }
            this.setState({
                accounts: this.props.teamMembers,
            });
        }
    }


    toggleLoginPopup = () => {
        this.setState({
            showLoginPopup: (!this.state.showLoginPopup),
        });
    }


    redirect(id) {
        window.location.href = `${this.props.reactHostUrl}/accounts/${id}/`;
    }
    
    
    render() {
        return(
            <>
            <Modal show={this.state.showLoginPopup} onHide={this.toggleLoginPopup}>
                <div className="modal-styling">
                    <Modal.Header closeButton>
                        <Modal.Title className="auth-header">Login</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Login />
                    </Modal.Body>
                </div>
            </Modal>
            {this.props.teamMembers !== "null" && this.state.accounts.length === 0
                ? <>
                    <br />
                    <div className="alert alert-primary normal-alert shadow-sm" role="alert">
                        This is a community-generated profile. To claim this project, please reach out to the team at CodingBootChamp. <a href="https://7x5u634seqx.typeform.com/to/Aag0v7MF" className="alert-link" id="alert-link" target="_blank" rel="noopener noreferrer">Contact us here</a>.
                    </div>
                </>
                : <div className="account-container d-flex col">
                    {this.state.accounts.map(account => {
                        return (
                            <div key={account.id} className="col">
                                {account.id != null 
                                    ? <div 
                                        className="anchor anchor-wrap card"
                                        onClick={this.state.isAuthenticated ? () => this.redirect(account.id) : () => this.toggleLoginPopup()}
                                    >
                                        <div className="account-card card shadow-sm">
                                            <div className="account-card-div d-flex ">
                                                <div className="account-card-div-left">
                                                    {account.profile_picture
                                                        ? <img className="profile-icon" src={`${this.state.imageBaseUrl}${account.profile_picture}`} alt={`${account.user.first_name} ${account.user.last_name}`} />
                                                        : <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="#b4b4b3" className="bi bi-person-circle profile-icon" viewBox="0 0 16 16">
                                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                                            <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                                        </svg>
                                                    }
                                                    <div onClick={this.state.isAuthenticated ? event => event.stopPropagation() : () => this.toggleLoginPopup()}>
                                                        <BootcampAlumniFollow
                                                            accountsHostUrl={this.props.accountsHostUrl}
                                                            accountId={account.id}
                                                            mobile={true}
                                                        />
                                                    </div>
                                                    <div className="account-links">
                                                        <span 
                                                            id={account.linkedin_url ? null : "dull-icon"} 
                                                            onClick={this.state.isAuthenticated 
                                                                ? event => {
                                                                    event.stopPropagation();
                                                                    window.open(`//${account.linkedin_url.replace(this.state.extension, "")}`, "_blank")
                                                                }
                                                                : () => this.toggleLoginPopup()
                                                            }
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill={account.linkedin_url ? "black" : "rgb(132, 132, 132)"} className="bi bi-linkedin" viewBox="0 0 16 16">
                                                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                                                            </svg>
                                                        </span>
                                                        <span 
                                                            id={account.remote_repository_url ? null : "dull-icon"}
                                                            onClick={this.state.isAuthenticated 
                                                                ? event => {
                                                                    event.stopPropagation();
                                                                    window.open(`//${account.remote_repository_url.replace(this.state.extension, "")}`, "_blank")
                                                                }
                                                                : () => this.toggleLoginPopup()                  
                                                            }
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill={account.remote_repository_url ? "black" : "rgb(132, 132, 132)"} className="bi bi-github" viewBox="0 0 16 16">
                                                                <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="account-card-div">
                                                    <div onClick={this.state.isAuthenticated ? event => event.stopPropagation() : () => this.toggleLoginPopup()}>
                                                        <BootcampAlumniFollow
                                                            accountsHostUrl={this.props.accountsHostUrl}
                                                            accountId={account.id}
                                                            mobile={false}
                                                        />
                                                    </div>
                                                    <p className="card-sub-title">{`${account.user.first_name} ${account.user.last_name}`}</p>
                                                    <p className="card-p-font">{account.headline}</p>
                                                    
                                                </div>
                                            </div>
                                            {this.props.teamMembers === "null"
                                                ? <div className="tag-div d-flex">
                                                    <div className="chip" id="hide-account-tags">
                                                        <p>{account.program.name}</p>
                                                    </div>
                                                    <div className="chip" id="hide-account-tags">
                                                        <p>{Moment(account.graduation_date).format('MMM, YYYY')}</p>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    : <div className="account-card-filler card"></div>
                                }
                            </div>
                        );
                    })}
                </div>
            }
            </>
        );
    }
}


export default BootcampAlumni;