import { React,useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../Authentication/Axios';
import Moment from 'moment';


function ProjectNotification(props) {
    const [congrats, setCongrats] = useState(false);


    let projectsHost = "http://localhost:8090";
    if (process.env.hasOwnProperty('REACT_APP_PROJECTS_SERVICE_HOST')) {
        projectsHost = process.env.REACT_APP_PROJECTS_SERVICE_HOST;
    }


    let reactHost = "http://localhost:3000";
    if (process.env.hasOwnProperty('REACT_APP_REACT_HOST')) {
        reactHost = process.env.REACT_APP_REACT_HOST;
    }


    const activateProject = () => {
        const data = {
            "discoverable": true,
            "activation_date": Moment(new Date()).format(),
        }
        axiosInstance.put(`${projectsHost}/api/projects/${props.project.id}/`, data)
        .then(() => {
            setCongrats(true);
        })
        .catch(error => console.log("projectForm: ", error))
    }


    const deactivateProject = () => {
        const data = {
            "discoverable": false,
            "activation_date": null,
        }

        axiosInstance.put(`${projectsHost}/api/projects/${props.project.id}/`, data)
        .then(() => {
            window.location.href = `${reactHost}/projects/${props.project.id}/`;
        })
        .catch(error => console.log("projectForm: ", error))
    }


    return (
        <>
            {props.project.discoverable
                ? <>
                    <div className="alert alert-primary normal-alert shadow-sm" role="alert">
                        <strong>This project is discoverable.</strong> Need to hide it? <span onClick={deactivateProject} className="alert-link" id="alert-link">Click here</span>.
                    </div>
                </>
                : <>
                    {props.minimumAssetsProvided
                    ? <>
                        {congrats
                            ? <div className="alert alert-primary normal-alert shadow-sm" role="alert">
                                <strong>Congratulations!</strong> Your project is now discoverable. Check it out <Link to="/projects/" className="alert-link" id="alert-link">here</Link>.
                            </div>
                            : <div className="alert alert-primary normal-alert shadow-sm" role="alert">
                                <strong>This project is in draft-mode.</strong> To make it discoverable,  <span onClick={activateProject} className="alert-link" id="alert-link">click here to activate it now</span>.
                            </div>
                        }
                    </>
                    : <div className="alert alert-primary normal-alert shadow-sm" role="alert">
                        {props.projectForm
                            ? <>
                                <strong>This project is in draft-mode.</strong> To make it discoverable, please upload the required assets.  Required assets are marked by an asterisk * in the form above.
                            </>
                            : <>
                                <strong>This project is in draft-mode.</strong> To make it discoverable, please upload the required assets <Link to="create/" className="alert-link" id="alert-link">here</Link>.
                            </>
                        }
                    </div>
                }
                </>
            }
        </>
    );
}


export default ProjectNotification;