import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import axiosInstance from '../Authentication/Axios';
import ProjectNotification from './ProjectNotification';


class ProjectForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountId: localStorage.getItem('id'),
            accountsHostUrl: "",
            projectsHostUrl: "",
            tagsHostUrl: "",
            reactHostUrl: "",
            currentAccount: {},
            project: {},
            title: "",
            headline: "",
            showIconField: false,
            icon: "",
            iconName: "",
            iconPath: "",
            iconHasChanged: false,
            remoteRepo: "",
            projectWebsite: "",
            launchDate: "",
            elevatorPitch: "",
            description: "",
            originalImages: [],
            originalImagesCount: 0,
            imagesPendingRemoval: false,
            imagesToRemove: [],
            addImageFieldCount: [1],
            videoUrl: "",
            showEmbedInstructions: false,
            testimonial: "",
            testimonialId: 0,
            hasTestimonial: false,
            testimonialWasUpdated: false,
            tags: [],
            selectedTags: [],
            searchableAccounts: [],
            selectedTeamMemberIds: [],
            selectedTeamMemberNames: [],
            teamMemberBasePath: "",
            finishedLoading: false,
            showAccountFormError: false,
            showTitleError: false,
            showHeadlineError: false,
            showElevatorPitchError: false,
            showUrlError: false,
            showEmbedError: false,
        }
    }


    async componentDidMount() {
        let accountsHost = "http://localhost:8100";
        if (process.env.hasOwnProperty('REACT_APP_ACCOUNTS_SERVICE_HOST')) {
            accountsHost = process.env.REACT_APP_ACCOUNTS_SERVICE_HOST;
        }

        let projectsHost = "http://localhost:8090";
        if (process.env.hasOwnProperty('REACT_APP_PROJECTS_SERVICE_HOST')) {
            projectsHost = process.env.REACT_APP_PROJECTS_SERVICE_HOST;
        }

        let tagsHost = "http://localhost:8060";
        if (process.env.hasOwnProperty('REACT_APP_TAGS_SERVICE_HOST')) {
            tagsHost = process.env.REACT_APP_TAGS_SERVICE_HOST;
        }

        let reactHost = "http://localhost:3000";
        if (process.env.hasOwnProperty('REACT_APP_REACT_HOST')) {
            reactHost = process.env.REACT_APP_REACT_HOST;
        }

        this.setState({
            accountsHostUrl: accountsHost,
            projectsHostUrl: projectsHost,
            tagsHostUrl: tagsHost,
            reactHostUrl: reactHost,
        });

        try {
            const projectResponse = await axiosInstance.get(`${projectsHost}/api/projects/${this.props.projectId}/`);
            const projectData = projectResponse.data.projects;
            this.setState({
                project: projectData,
                teamMemberBasePath: "https://coding-boot-champ-assets.s3.amazonaws.com/media/",
            });

            const projectTeamMembers = [];
            const projectTeamMemberIds = [];
            projectData.team_members.forEach(teamMember => {
                projectTeamMembers.push(teamMember);
                projectTeamMemberIds.push(teamMember.id);
            });
            if (projectData.title != null) {
                this.setState({
                    title: projectData.title
                });
            }
            if (projectData.headline != null) {
                this.setState({
                    headline: projectData.headline
                });
            }
            if (projectData.icon != null) {
                this.setState({
                    icon: projectData.icon,
                    iconPath: `https://coding-boot-champ-assets.s3.amazonaws.com/media/${projectData.icon}`,
                });
            }
            if (projectData.remote_repository_url != null) {
                this.setState({
                    remoteRepo: projectData.remote_repository_url
                });
            }
            if (projectData.website_url != null) {
                this.setState({
                    projectWebsite: projectData.website_url
                });
            }
            if (projectData.launch_date != null) {
                this.setState({
                    launchDate: projectData.launch_date
                });
            }
            if (projectData.elevator_pitch != null) {
                this.setState({
                    elevatorPitch: projectData.elevator_pitch
                });
            }
            if (projectData.description != null) {
                this.setState({
                    description: projectData.description
                });
            }
            if (projectData.video_url != null) {
                this.setState({
                    videoUrl: projectData.video_url
                });
            }
            if (projectTeamMembers.length > 0) {
                this.setState({
                    selectedTeamMemberNames: projectTeamMembers
                });
            }
            if (projectTeamMemberIds.length > 0) {
                this.setState({
                    selectedTeamMemberIds: projectTeamMemberIds
                });
            }
        } catch (error) {
            console.log("There was an error loading project", error);
        }

        try {
            const projectTagsResponse = await axiosInstance.get(`${projectsHost}/api/projects/${this.props.projectId}/tags/`);
            const projectTagsData = projectTagsResponse.data.project_tags.sort((a, b) => (a.order > b.order) ? 1 : -1);
            this.setState({
                selectedTags: projectTagsData
            });
        } catch (error) {
            console.log("There was an error loading project tags", error);
        }

        const tagsResponse = await fetch(`${tagsHost}/api/tags/`);
        if (tagsResponse.ok) {
            const tagsData = await tagsResponse.json();
            const sortedTags = tagsData.tags.sort((a, b) => (a.name > b.name) ? 1 : -1);
            this.setState({
                tags: sortedTags
            });
        } else {
            console.log("There was an error fetching list of tags: ", tagsResponse);
        }

        const imagesResponse = await fetch(`${projectsHost}/api/projects/${this.props.projectId}/images/`);
        if (imagesResponse.ok) {
            const imagesData = await imagesResponse.json();
            if (imagesData.images.length > 0) {
                const currentImages = [];
                imagesData.images.forEach(image => {
                    if (image.is_current === true) {
                        currentImages.push(image);
                    }
                });

                const sortedCurrentImages = currentImages.sort((a, b) => (a.order > b.order) ? 1 : -1);

                const finalImagesArray = [];
                sortedCurrentImages.forEach(image => {
                    let imagePath = image.image;
                    let imageObj = {
                        "image": image,
                        "imagePath": `https://coding-boot-champ-assets.s3.amazonaws.com/media/${imagePath}`
                    }
                    finalImagesArray.push(imageObj);
                });
                this.setState({
                    originalImages: finalImagesArray,
                    originalImagesCount: finalImagesArray.length,
                });
            }
        } else {
            console.log("There was an error fetching list of images: ", imagesResponse);
        }

        const testimonialsResponse = await fetch(`${projectsHost}/api/testimonials/`);
        if (testimonialsResponse.ok) {
            const testimonialsData = await testimonialsResponse.json();
            testimonialsData.testimonials.forEach(testimonial => {
                if (testimonial.account.id === parseInt(this.state.accountId)) {
                    this.setState({
                        testimonial: testimonial.testimonial,
                        testimonialId: testimonial.id,
                        hasTestimonial: true,
                    });
                }
            });
        } else {
            console.log("There was an error fetching list of testimonials: ", testimonialsResponse);
        }

        axiosInstance.get(`${accountsHost}/api/accounts/${this.state.accountId}/`)
        .then(response => {
            this.setState({
                currentAccount: response.data.account
            });
        })
        .catch(error => console.log("currentUsersAccount: ", error))

        const AccountsResponse = await fetch(`${accountsHost}/api/accounts/`);
        if (AccountsResponse.ok) {
            const accountsData = await AccountsResponse.json();
            const accountSearchResults = [];
            accountsData.accounts.forEach(account => {
                if (account.program != null) {
                    if (
                        account.program.id === this.state.project.program.id &&
                        Moment(account.graduation_date).format('MM-YY') === Moment(this.state.currentAccount.graduation_date).format('MM-YY') &&
                        account.id !== parseInt(this.state.accountId)
                    ) {
                        accountSearchResults.push(account);
                    }
                }
            });
            this.setState({
                searchableAccounts: accountSearchResults
            });
        } else {
            console.log("There was an error fetching list of accounts: ", AccountsResponse);
        }

        this.finishedLoading();
    }


    finishedLoading = () => {
        setTimeout(() => {
            this.setState({
                finishedLoading: true,
            });
        }, 100);
    }


    redirect() {
        window.location.href = "/projects";
    }


    handleChange = event => {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });
    }


    showEmbedInstructions = () => {
        this.setState({
            showEmbedInstructions: !(this.state.showEmbedInstructions)
        });
    }


    handleTagChange = event => {
        const tagId = event.target.value;

        const selectedTags = this.state.selectedTags;
        let lastInOrder = 0;
        let alreadySelected = false;

        selectedTags.forEach(tag => {
            if (tag.order > lastInOrder) {
                lastInOrder = tag.order;
            } 
            if (tag.tag.id === parseInt(tagId)) {
                alreadySelected = true;
            }
        });

        if (!(alreadySelected)) {
            const selectedTagObj = {
                "project_id": this.props.projectId,
                "tag_id": parseInt(tagId),
                "order": lastInOrder + 1,
            }

            axiosInstance.post(`${this.state.projectsHostUrl}/api/projects/${this.props.projectId}/tags/`, selectedTagObj)
            .catch(error => console.log("post projectTags: ", error))

                .then(() => {
                    axiosInstance.get(`${this.state.projectsHostUrl}/api/projects/${this.props.projectId}/tags/`)
                    .then(response => {
                        const projectTagsData = response.data.project_tags.sort((a, b) => (a.order > b.order) ? 1 : -1);
                        this.setState({
                            selectedTags: projectTagsData
                        });
                    })
                    .catch(error => console.log("get projectTags: ", error))
                });
        }
    }


    handleTagClick = tag => {
        axiosInstance.delete(`${this.state.projectsHostUrl}/api/projects/tags/${tag.id}/`)
        .catch(error => console.log("delete projectTags: ", error))

            .then(() => {
                axiosInstance.get(`${this.state.projectsHostUrl}/api/projects/${this.props.projectId}/tags/`)
                .then(response => {
                    const projectTagsData = response.data.project_tags.sort((a, b) => (a.order > b.order) ? 1 : -1);
                    this.setState({
                        selectedTags: projectTagsData
                    });
                })
                .catch(error => console.log("get projectTags: ", error))
            });
    }


    handleTestimonialChange = event => {
        const value = event.target.value;
        this.setState({
            testimonial: value,
            testimonialWasUpdated: true,
        });
    }


    handleTeamMemberChange = event => {
        const {value} = event.target;

        const selectedTeamMemberNames = this.state.selectedTeamMemberNames;
        this.state.searchableAccounts.forEach(account => {
            if (parseInt(value) === account.id && !(selectedTeamMemberNames.includes(account))) {
                selectedTeamMemberNames.push(account);
            }
        })

        const selectedTeamMemberIds = this.state.selectedTeamMemberIds;
        selectedTeamMemberIds.push(value)
        this.setState({
            selectedTeamMemberIds: selectedTeamMemberIds,
            selectedTeamMemberNames: selectedTeamMemberNames,
        });
    }


    handleTeamMemberClick = account => {
        const selectedTeamMemberNames = this.state.selectedTeamMemberNames;
        const nameIndex = selectedTeamMemberNames.indexOf(account);
        selectedTeamMemberNames.splice(nameIndex, 1);

        const selectedTeamMemberIds = this.state.selectedTeamMemberIds;
        const idIndex = selectedTeamMemberIds.indexOf(account.id);
        selectedTeamMemberIds.splice(idIndex, 1);

        this.setState({
            selectedTeamMemberIds: selectedTeamMemberIds,
            selectedTeamMemberNames: selectedTeamMemberNames,
        });
    }


    showIconField = () => {
        this.setState({
            showIconField: true,
        });
    }


    setIcon = icon => {
        this.setState({
            icon: icon,
            iconName: this.props.projectId,
            iconHasChanged: true,
        });
    }


    convertImageToURI = image => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsBinaryString(image);
            fileReader.onload = function (e) {
                // Converts binary data to base64
                const base64ImageString = btoa(e.target.result);
                resolve(`data:${image.type};base64,${base64ImageString}`);
            }
            fileReader.onerror = function (e) {
                console.log('Could not convert image to base64. Error: ', e);
                reject();
            }
        })
    }


    saveIcon = async (id, dataURI) => {
        const date = Moment(new Date()).format('MMM Do YY, h:mm:ss a')
        const data = {
            "icon": dataURI,
            "icon_name": id + "_" + date,
        }

        axiosInstance.put(`${this.state.projectsHostUrl}/api/projects/${id}/`, data)
            .catch(error => console.log("icon: ", error))
    }


    setImage = image => {
        this.convertImageToURI(image)
        .then(dataURI => {
            this.saveImage(this.props.projectId, dataURI);
        })
        .catch((e) => {
            console.log('Could not upload image: ', e);
        });
    }


    saveImage = async (id, dataURI) => {
        const date = Moment(new Date()).format('MMM Do YY, h:mm:ss a');

        const originalImages = this.state.originalImages;
        let lastInOrder = this.state.originalImagesCount;

        if (lastInOrder > 0) {
            originalImages.forEach(image => {
                if (image.image.order > lastInOrder) {
                    lastInOrder = image.image.order;
                } 
            });
        }

        const data = {
            "image": dataURI,
            "image_name": id + "_" + date,
            "is_current": true,
            "project_id": id,
            "order": lastInOrder + 1,
        }

        axiosInstance.post(`${this.state.projectsHostUrl}/api/images/`, data)
            .then(() => {
                if (originalImages.length === 0) {
                    this.setState({
                        originalImagesCount: this.state.originalImagesCount + 1
                    });
                }
            })
            .catch(error => console.log("imagesToSave: ", error))
    }


    removeImage = (imageId) => {
        const imagesToRemoveArray = this.state.imagesToRemove;
        if (!(this.state.imagesToRemove.includes(imageId))) {
            imagesToRemoveArray.push(imageId);
        }
        this.setState({
            imagesToRemove: imagesToRemoveArray,
            imagesPendingRemoval: true,
        });
    }


    undoRemoveImage = (imageId) => {
        const imagesToRemoveArray = this.state.imagesToRemove;
        imagesToRemoveArray.pop(imageId)
        this.setState({
            imagesToRemove: imagesToRemoveArray,
        });
    }


    addImageUploadField = () => {
        const addImageUploadFieldArray = this.state.addImageFieldCount;
        const index = addImageUploadFieldArray.length - 1;
        const count = addImageUploadFieldArray[index] + 1;
        addImageUploadFieldArray.push(count);
        this.setState({
            addImageFieldCount: addImageUploadFieldArray,
        });
    }


    handleSubmit = async event => {
        event.preventDefault();

        if (this.state.videoUrl !== "" && !(this.state.videoUrl.includes("embed"))) {
            this.setState({
                showEmbedError: true,
            });
            return false;
        }

        if (this.state.iconHasChanged) {
            this.convertImageToURI(this.state.icon)
            .then(dataURI => {
                this.saveIcon(this.props.projectId, dataURI);
            })
            .catch((e) => {
                console.log('Could not upload icon: ', e);
            });
        }

        if (this.state.imagesPendingRemoval) {
            this.state.imagesToRemove.forEach(imageId => {
                const data = {
                    "is_current": false,
                }

                axiosInstance.put(`${this.state.projectsHostUrl}/api/images/${imageId}/`, data)
                .catch(error => console.log("imagesToRemove: ", error))
            });
        }

        const testimonialData = {
            "testimonial": this.state.testimonial,
            "account": this.state.accountId,
            "project": this.state.project.id,
        }

        if (this.state.testimonialWasUpdated) {
            if (this.state.hasTestimonial) {
                const testimonialResponse = await fetch (`${this.state.projectsHostUrl}/api/testimonials/${this.state.testimonialId}/`, {
                    method: 'PUT',
                    body: JSON.stringify(testimonialData),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (!testimonialResponse.ok) {
                    console.log("There was an error updating testimonial");
                }
            } else {
                const testimonialResponse = await fetch (`${this.state.projectsHostUrl}/api/testimonials/`, {
                    method: 'POST',
                    body: JSON.stringify(testimonialData),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (!testimonialResponse.ok) {
                    console.log("There was an error creating testimonial");
                }
            }
        }

        const projectData = {...this.state};
        projectData["remote_repository_url"] = projectData.remoteRepo;
        projectData["website_url"] = projectData.projectWebsite;
        projectData["elevator_pitch"] = projectData.elevatorPitch;
        projectData["video_url"] = projectData.videoUrl;
        projectData["team_members_id"] = projectData.selectedTeamMemberIds;
        
        if (projectData.launchDate !== "") {
            projectData["launch_date"] = projectData.launchDate;
        }

        delete projectData.accountId;
        delete projectData.accountsHostUrl;
        delete projectData.projectsHostUrl;
        delete projectData.tagsHostUrl;
        delete projectData.reactHostUrl;
        delete projectData.currentAccount;
        delete projectData.project;
        delete projectData.showIconField;
        delete projectData.icon;
        delete projectData.iconName;
        delete projectData.iconPath;
        delete projectData.iconHasChanged;
        delete projectData.remoteRepo;
        delete projectData.projectWebsite;
        delete projectData.launchDate;
        delete projectData.elevatorPitch;
        delete projectData.originalImages;
        delete projectData.originalImagesCount;
        delete projectData.imagesPendingRemoval;
        delete projectData.imagesToRemove;
        delete projectData.addImageFieldCount;
        delete projectData.videoUrl;
        delete projectData.showEmbedInstructions;
        delete projectData.testimonial;
        delete projectData.testimonialId;
        delete projectData.hasTestimonial;
        delete projectData.testimonialWasUpdated;
        delete projectData.tags;
        delete projectData.selectedTags;
        delete projectData.teamMembers;
        delete projectData.searchableAccounts;
        delete projectData.selectedTeamMemberIds;
        delete projectData.selectedTeamMemberNames;
        delete projectData.teamMemberBasePath;
        delete projectData.finishedLoading;
        delete projectData.showAccountFormError;
        delete projectData.showTitleError;
        delete projectData.showHeadlineError;
        delete projectData.showElevatorPitchError;
        delete projectData.showUrlError;
        delete projectData.showEmbedError;

        axiosInstance.put(`${this.state.projectsHostUrl}/api/projects/${this.state.project.id}/`, projectData)
        .then(() => {
            setTimeout(() => {
                window.location.href = `${this.state.reactHostUrl}/projects/${this.state.project.id}/`;
            }, 500);
        })
        .catch(error => {
            console.log("projectForm: ", error);

            if (error.response.data.message.title) {
                if (error.response.data.message.title.includes("Ensure this field has no more than 80 characters.")) {
                    this.setState({
                        showTitleError: true,
                    });
                }
            } else {
                this.setState({
                    showTitleError: false,
                });
            }

            if (error.response.data.message.headline) {
                if (error.response.data.message.headline.includes("Ensure this field has no more than 100 characters.")) {
                    this.setState({
                        showHeadlineError: true,
                    });
                }
            } else {
                this.setState({
                    showHeadlineError: false,
                });
            }

            if (error.response.data.message.remote_repository_url) {
                if (error.response.data.message.remote_repository_url.includes("Enter a valid URL.")) {
                    this.setState({
                        showUrlError: true,
                    });
                }
            } 

            if (error.response.data.message.website_url) {
                if (error.response.data.message.website_url.includes("Enter a valid URL.")) {
                    this.setState({
                        showUrlError: true,
                    });
                }
            }

            if (error.response.data.message.video_url) {
                if (error.response.data.message.video_url.includes("Enter a valid URL.")) {
                    this.setState({
                        showEmbedError: true,
                    });
                }
            }

            if (error.response.data.message.elevator_pitch) {
                if (error.response.data.message.elevator_pitch.includes("Ensure this field has no more than 300 characters.")) {
                    this.setState({
                        showElevatorPitchError: true,
                    });
                }
            } else {
                this.setState({
                    showElevatorPitchError: false,
                });
            }
        })
    }


    render() {
        return(
            <>
            {this.state.finishedLoading
            ? <>
            {!(this.state.selectedTeamMemberIds.includes(parseInt(this.state.accountId))) && (this.state.currentAccount.is_staff !== true)
            ? this.redirect()
            : <>
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="container">
                <div className="row">
                    <div className="offset-2 col-8">
                        <br />
                        <div className="d-flex">
                            <p className="form-title">Edit Your Project</p>
                            <Link to={`/projects/${this.props.projectId}/`} className="form-back-to-profile">(Back to Project)</Link>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-div">
                                <div className="mb-3">
                                    <p className="form-header">Project Details</p>
                                    <label htmlFor="title">Title *</label>
                                    <p className="instructions">Need to update your title?  Please <a href="https://7x5u634seqx.typeform.com/to/Aag0v7MF" target="_blank" rel="noopener noreferrer" id="error-alert-link">contact support</a>.</p>
                                    <input onChange={this.handleChange} value={this.state.title} placeholder="CarCar" required type="text" name="title" id="title" className="form-input form-control" disabled="disabled"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="headline">Headline *</label>
                                    <input onChange={this.handleChange} value={this.state.headline} placeholder="The Premier Solution for Automobile Dealership Management" type="text" name="headline" id="headline" className="form-input form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="elevatorPitch">Elevator Pitch *</label>
                                    <p className="instructions">One to three sentences stating the purpose of your app.</p>
                                    <textarea rows="3" onChange={this.handleChange} value={this.state.elevatorPitch} placeholder="A full-stack web application to manage car sales and repair services for an auto dealership..." type="text" name="elevatorPitch" id="elevatorPitch" className="form-input form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="description">Description *</label>
                                    <p className="instructions">A longer description typically pulled from your repo's README describing your project in greater detail, including technologies used, breakdown of tasks and roles, challenges, successes, etc.</p>
                                    <textarea rows="9" onChange={this.handleChange} value={this.state.description} placeholder="CarCar was built with Django and React using Docker containers and a microservice architecture connected via polling..." type="text" name="description" id="description" className="form-input form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="icon">Icon *</label>
                                    <p className="instructions">Recommended dimensions: 400 by 400 pixels.</p>
                                    {this.state.showIconField
                                        ? <input
                                            className="form-input form-control"
                                            type="file"
                                            name="icon"
                                            id="icon"
                                            accept="image/*"
                                            onChange={event => {
                                                this.setIcon(event.target.files[0])
                                            }}
                                        />
                                        : <>
                                            {this.state.finishedLoading
                                                ? <>
                                                    {this.state.icon
                                                        ? <div className="icon-field d-flex">
                                                            <img className="icon-preview" src={this.state.iconPath} alt="profile"/>
                                                            <p onClick={this.showIconField} className="icon-update">Update Icon</p>
                                                        </div>
                                                        : <div className="icon-field d-flex">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#b4b4b3" className="bi bi-code-square icon-preview" viewBox="0 0 16 16">
                                                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                                                <path d="M6.854 4.646a.5.5 0 0 1 0 .708L4.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0zm2.292 0a.5.5 0 0 0 0 .708L11.793 8l-2.647 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708 0z"/>
                                                            </svg>
                                                            <p onClick={this.showIconField} className="icon-update">Update Icon</p>
                                                        </div>
                                                    }
                                                </>
                                                : null
                                            }
                                        </>
                                    }
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="remoteRepo">Remote Repo *</label>
                                    <p className="instructions">Please include <u>https://</u> in the URL you provide.</p>
                                    <input onChange={this.handleChange} value={this.state.remoteRepo} placeholder="https://gitlab.com/username/project_name/" type="text" name="remoteRepo" id="remoteRepo" className="form-input form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="projectWebsite">Project Website</label>
                                    <p className="instructions">Please include <u>https://</u> in the URL you provide.</p>
                                    <input onChange={this.handleChange} value={this.state.projectWebsite} placeholder="https://myproject.com" type="text" name="projectWebsite" id="projectWebsite" className="form-input form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="launchDate">Project Launch Date *</label>
                                    <input onChange={this.handleChange} value={this.state.launchDate} type="date" name="launchDate" id="launchDate" className="form-input form-control"/>
                                </div>
                                <br />
                            </div>
                            <div className="form-div">
                                <br />
                                <p className="form-header">Applied Technical Skills</p>
                                <div className="mb-3">
                                    <label htmlFor="tags">Tags *</label>
                                    <p className="instructions">Don't see a tag you'd like to add?  Send it to us <a href="https://7x5u634seqx.typeform.com/to/Aag0v7MF" target="_blank" rel="noopener noreferrer" id="error-alert-link">here</a>, and we'll add it to your project.</p>
                                    <select onChange={this.handleTagChange} value={this.state.tags} multiple={true} name="tags" id="tags-desktop" className="form-select tags-select-desktop">
                                        <option value="">Add Technical Skills</option>
                                        {
                                            this.state.tags.map(tag => {
                                                return <option key={tag.id} value={tag.id}>{tag.name}</option>
                                            })
                                        }
                                    </select>
                                    <select onChange={this.handleTagChange} name="tags" id="tags-mobile" className="form-select tags-select-mobile">
                                        <option value="">Add Technical Skills</option>
                                        {
                                            this.state.tags.map(tag => {
                                                return <option key={tag.id} value={tag.id}>{tag.name}</option>
                                            })
                                        }
                                    </select>
                                    <div className="tags-form">
                                        {this.state.selectedTags.length > 0 ? <p className="instructions-sm">Click tag to remove</p> : null}
                                        {this.state.selectedTags.map(tag => {
                                            return <div key={tag.id} onClick={() => this.handleTagClick(tag)} className="profile-chip" value={tag} id="tag-form-chip">
                                                <p>{tag.tag.name}</p>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="form-div">
                                <br />
                                <p className="form-header">Demo</p>
                                <div className="mb-3">
                                    <label htmlFor="images">Include Screenshots of Your Application *</label>
                                    {this.state.originalImages.length > 0
                                        ? <>
                                            {this.state.originalImages.map(image => {
                                                return (
                                                    <div key={image.image.id} id="images">
                                                        {this.state.finishedLoading
                                                            ? <div className="image-field d-flex" id="screenshot-buffer">
                                                                <img className="image-preview" src={image.imagePath} alt="profile"/>
                                                                {this.state.imagesToRemove.includes(image.image.id)
                                                                    ? <div className="image-removal-div">
                                                                        <p className="image-removal">Image Removed</p>
                                                                        <p onClick={() => this.undoRemoveImage(image.image.id)} className="image-removal-undo">Undo</p>
                                                                    </div>
                                                                    : <p onClick={() => this.removeImage(image.image.id)} className="image-update">Remove Screenshot</p>
                                                                }
                                                            </div>
                                                            : null
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </>
                                        : null
                                    }
                                    {this.state.originalImages.length > 0
                                        ? <label htmlFor="images">Add More Screenshots</label>
                                        : null
                                    }
                                    {this.state.addImageFieldCount.map(count => {
                                        return (
                                            <div key={count} className="image-input-map">
                                                <p className="instructions">Recommended dimensions: 1270 by 760 pixels.</p>
                                                <input
                                                    className="form-input form-control"
                                                    type="file"
                                                    name="image"
                                                    id="image"
                                                    accept="image/*"
                                                    onChange={event => {
                                                        this.setImage(event.target.files[0])
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                    <div>
                                        <p onClick={() => this.addImageUploadField()} className="add-image-cta">+ Upload Another Screenshot</p>
                                    </div>
                                    <br />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="videoUrl">Embed a Video Demo of Your Application via YouTube</label>
                                    <p className="instructions">At this time, only videos hosted on YouTube are supported.  To add your demo video, please input the YouTube <strong>embed link</strong> below.  Please include <u>https://</u> in the URL you provide.</p>
                                    <p className="embed-instructions-click" onClick={this.showEmbedInstructions}>Where can I find my YouTube embed link?</p>
                                    {this.state.showEmbedInstructions
                                        ? <div>
                                            <ol>
                                                <li className="embed-instructions">Go to YouTube.</li>
                                                <li className="embed-instructions">Navigate to the video you wish to embed.</li>
                                                <li className="embed-instructions">Click the Share link below the video, then click the Embed link.</li>
                                                <li className="embed-instructions">Within the iframe tag, copy the source URL excluding the quotation marks.</li>
                                                <p className="embed-instructions">e.g. src="<span className="embed-example">https://www.youtube.com/embed/GP5jXj0O4OM</span>"</p>
                                                <li className="embed-instructions">Paste the URL, including the 'https://' prefix, into the field below.</li>
                                            </ol>
                                        </div>
                                        : null
                                    }
                                    <input onChange={this.handleChange} value={this.state.videoUrl} placeholder="https://www.youtube.com/embed/xxxxxxxxxxx/" type="text" name="videoUrl" id="videoUrl" className="form-input form-control"/>
                                </div>
                                <br />
                            </div>
                            <div className="form-div">
                                <br />
                                <p className="form-header">Testimonials</p>
                                <div className="mb-3">
                                    <label htmlFor="testimonial">Share Your Personal Experience</label>
                                    <p className="instructions">This is your opportunity to speak to your personal impact and lessons learned.  What was your biggest takeaway, your greatest challenge, or proudest accomplishment?  Please note, each of your teammates will have the option to provide a testimonial of their own from their account.</p>
                                    <textarea rows="5" onChange={this.handleTestimonialChange} value={this.state.testimonial} placeholder="I'm most proud of the work I did spearheading our team's efforts to integrate our app with the third-party vehicle data API, CarQuery..." type="text" name="testimonial" id="testimonial" className="form-input form-control"/>
                                </div>
                                <br />
                            </div>
                            <div className="form-div">
                                <br />
                                <p className="form-header">Team</p>
                                <div className="mb-3">
                                    <label htmlFor="teamMembers">Add Your Team Members *</label>
                                    <p className="instructions">Please note, only students from your program who have registered with CodingBootChamp and have indicated which bootcamp and program they're attending/attended in their profile are included below.</p>
                                    <select onChange={this.handleTeamMemberChange} value={this.state.teamMembers} multiple={true} name="teamMembers" id="teamMembers" className="form-select team-members-select-desktop">
                                        {
                                            this.state.searchableAccounts.map(account => {
                                                return <option key={account.id} value={account.id}>{`${account.user.first_name} ${account.user.last_name}`}</option>
                                            })
                                        }
                                    </select>
                                    <select onChange={this.handleTeamMemberChange} name="teamMembers" id="teamMembers" className="form-select team-members-select-mobile">
                                        {
                                            this.state.searchableAccounts.map(account => {
                                                return <option key={account.id} value={account.id}>{`${account.user.first_name} ${account.user.last_name}`}</option>
                                            })
                                        }
                                    </select>
                                    <div className="tags-form">
                                        {this.state.selectedTeamMemberNames.length > 0 ? <p className="instructions-sm">Click team member to remove</p> : null}
                                        {this.state.selectedTeamMemberNames.map(account => {
                                            return <div key={account.id} onClick={() => this.handleTeamMemberClick(account)} className="team-member-chip" value={account} id={account.id !== parseInt(this.state.accountId) ? "team-member-form-chip" : "team-member-no-click"}>
                                                {account.profile_picture
                                                    ? <img src={`${this.state.teamMemberBasePath}${account.profile_picture}`} alt={`${account.user.first_name} ${account.user.last_name}`}/>
                                                    : <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#b4b4b3" className="bi bi-person-circle team-member-no-profile-pic" viewBox="0 0 16 16">
                                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                                        <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                                    </svg>
                                                }
                                                <p>{`${account.user.first_name} ${account.user.last_name}`}</p>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <br />
                            <ProjectNotification 
                                project={this.state.project}
                                projectForm={true}
                            />
                            <br />
                            {this.state.showAccountFormError
                                ? <div className="alert alert-danger error-alert shadow-sm" role="alert">
                                    Please be sure to complete all required fields marked by an asterisk.  If you have any questions, please feel free to <a href="https://7x5u634seqx.typeform.com/to/Aag0v7MF" target="_blank" rel="noopener noreferrer"  className="alert-link" id="error-alert-link">contact support</a>.
                                </div>
                                : null
                            }
                            {this.state.showTitleError
                                ? <div className="alert alert-danger error-alert shadow-sm" role="alert">
                                    Looks like your title exceeds the max length allowed.  Please keep your title below 80 characters.
                                </div>
                                : null
                            }
                            {this.state.showHeadlineError
                                ? <div className="alert alert-danger error-alert shadow-sm" role="alert">
                                    Looks like your headline exceeds the max length allowed.  Please keep your headline below 100 characters.
                                </div>
                                : null
                            }
                            {this.state.showElevatorPitchError
                                ? <div className="alert alert-danger error-alert shadow-sm" role="alert">
                                    Looks like your elevator pitch exceeds the max length allowed.  Please keep your elevator pitch below 300 characters.
                                </div>
                                : null
                            }
                            {this.state.showUrlError
                                ? <div className="alert alert-danger error-alert shadow-sm" role="alert">
                                    Please be sure your URLs are formatted correctly.  If you have any questions, please feel free to <a href="https://7x5u634seqx.typeform.com/to/Aag0v7MF" target="_blank" rel="noopener noreferrer" className="alert-link" id="error-alert-link">contact support</a>.
                                </div>
                                : null
                            }
                            {this.state.showEmbedError
                                ? <div className="alert alert-danger error-alert shadow-sm" role="alert">
                                    Please be sure the embed link for your video demo is formatted correctly.  If you have any questions, please feel free to <a href="https://7x5u634seqx.typeform.com/to/Aag0v7MF" target="_blank" rel="noopener noreferrer" className="alert-link" id="error-alert-link">contact support</a>.
                                </div>
                                : null
                            }
                            <button className="btn btn-primary">Save</button>
                            <br />
                        </form>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            </>
            }
            </>
            : null
            }
            </>
        );
    }
}


export default ProjectForm;