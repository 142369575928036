import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import AccountBio from './AccountBio';
import AccountProjects from './AccountProjects';
import AccountNotification from './AccountNotification';
import AccountFollowFromProfile from './AccountFollowFromProfile';


class AccountProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountId: localStorage.getItem('id'),
            remoteRepo: "",
            linkedIn: "",
            website: "",
            imageBaseUrl: "https://coding-boot-champ-assets.s3.amazonaws.com/media/",
            currentUsersAccount: false,
            followers: [],
            userHasFollowed: false,
            followClickCount: 0,
            finishedLoading: false,
        }
    }
    

    componentDidMount() {
        setTimeout(async () => {
            if (this.props.account.id === parseInt(this.state.accountId)) {
                this.setState({
                    currentUsersAccount: true,
                });
            }

            try {
                const followersResponse =  await fetch(`${this.props.accountsHostUrl}/api/followers/${this.props.id}/`)
                const followerData = await followersResponse.json();
                const followerArray = [];
                followerData.followers.forEach(follower => {
                    followerArray.push(follower.follower.id);
                });
                if (followerArray.includes(parseInt(this.state.accountId))) {
                    this.setState({
                        followers: followerData.followers,
                        userHasFollowed: true,
                    });
                }
            } catch (error) {
                console.log("There was an error loading followers", error)
            }

            const extension = /^https?:\/\//;
            if (this.props.account.remote_repository_url != null) {
                const remoteRepo = this.props.account.remote_repository_url.replace(extension, "");
                this.setState({
                    remoteRepo: remoteRepo,
                });
            }

            if (this.props.account.linkedin_url != null) {
                const linkedIn = this.props.account.linkedin_url.replace(extension, "");
                this.setState({
                    linkedIn: linkedIn,
                });
            }

            if (this.props.account.website_url != null) {
                const website = this.props.account.website_url.replace(extension, "");
                this.setState({
                    website: website,
                });
            }

            this.setState({
                finishedLoading: true,
            });
        }, 300);
    }


    updateUserHasFollowed() {
        return async event => {
            if (!(this.state.userHasFollowed)) {
                event.preventDefault();
                const data = {
                    "followee": this.props.id,
                    "follower": this.state.accountId,
                }
                const response = await fetch (`${this.props.accountsHostUrl}/api/follows/`, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    if (this.state.followers.length === 0) {
                        this.setState({
                            userHasFollowed: true,
                            followClickCount: this.state.followClickCount + 2,
                        });
                    } else {
                        this.setState({
                            userHasFollowed: true,
                            followClickCount: this.state.followClickCount + 1,
                        });
                    }
                }
            } else {
                event.preventDefault();
                let followId = 0;
                this.state.followers.forEach(follower => {
                    if (follower.follower.user.id === parseInt(this.state.accountId)) {
                        followId = follower.id;
                    }
                });
                const response = await fetch (`${this.props.accountsHostUrl}/api/follows/${followId}/`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    this.setState({
                        userHasFollowed: false,
                        followClickCount: this.state.followClickCount + 1,
                    });
                }
            }
        }
    }


    redirect() {
        window.location.href = `${this.props.reactHostUrl}/projects`;
    }

    
    render() {
        return(
            <>
            {this.state.finishedLoading
            ? <>
                {!(this.state.currentUsersAccount)
                    ? null
                    : <>
                        {this.props.account.discoverable
                            ? null
                            : <AccountNotification />
                        }
                    </>
                }
                <div className="container d-flex">
                    <div className="p-2">
                        {this.state.finishedLoading ? <div>
                            {this.props.account.profile_picture
                                ? <img className="logo" src={`${this.state.imageBaseUrl}${this.props.account.profile_picture}`} alt={`${this.props.account.user.first_name} ${this.props.account.user.last_name}`} />
                                : <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" fill="#b4b4b3" className="bi bi-person-circle logo" viewBox="0 0 16 16">
                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                </svg>
                            }
                            {this.state.currentUsersAccount
                                ? <Link type="button" className="btn btn-primary follow-button-mobile" to={"create/"}>
                                    Edit
                                </Link>
                                : <AccountFollowFromProfile
                                    accountId={this.state.accountId}
                                    currentUsersAccount={this.state.currentUsersAccount}
                                    mobile={true}
                                    userHasFollowed={this.state.userHasFollowed}
                                    updateUserHasFollowed={() => this.updateUserHasFollowed()}
                                    followClickCount={this.state.followClickCount}
                                    accountsHostUrl={this.props.accountsHostUrl}
                                    projectsHostUrl={this.props.projectsHostUrl}
                                    reactHostUrl={this.props.reactHostUrl}
                                />
                            }
                        </div> : null}
                        {this.state.finishedLoading ? <div className="profile-info">
                            <h2 className="header-title">{`${this.props.account.user.first_name} ${this.props.account.user.last_name}`}</h2>
                            <p className="sub-title">{this.props.account.headline ? this.props.account.headline : <em>Add a Headline to Your Profile</em>}</p>
                            {this.props.account.bootcamp == null
                            ? null
                            : <div className="d-flex profile-tag-div">
                                <div className="profile-chip">
                                    
                                    <img src={this.props.account.bootcamp.logo} alt={`${this.props.account.bootcamp.name} logo`}/>
                                    <p>{this.props.account.bootcamp.name}</p>
                                </div>
                                <div className="profile-chip" id="hide-program-name">
                                    <p>{this.props.account.program.name}</p>
                                </div>
                                <div className="profile-chip">
                                    <p>{Moment(this.props.account.graduation_date).format('MMM, YYYY')}</p>
                                </div>
                            </div>}
                        </div> : null}
                        <AccountFollowFromProfile 
                            accountId={this.state.accountId}
                            currentUsersAccount={this.state.currentUsersAccount}
                            mobile={false}
                            userHasFollowed={this.state.userHasFollowed}
                            updateUserHasFollowed={() => this.updateUserHasFollowed()}
                            followClickCount={this.state.followClickCount}
                            accountsHostUrl={this.props.accountsHostUrl}
                            projectsHostUrl={this.props.projectsHostUrl}
                            reactHostUrl={this.props.reactHostUrl}
                        />
                    </div>
                </div>
                <div className="container d-flex" id="container-tabs">
                    <div className="tab-link-div d-flex">
                        <a className="nav-link tab-link d-flex" id={this.props.account.remote_repository_url ? null : "dull-icon"} target="_blank" rel="noopener noreferrer" href={`//${this.state.remoteRepo}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={this.props.account.remote_repository_url ? "black" : "rgb(132, 132, 132)"} className="bi bi-github" viewBox="0 0 16 16" id="account-socials">
                                <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
                            </svg>
                            <p className="mobile-tab">Remote Repo</p>
                        </a>
                        <a className="nav-link tab-link d-flex" id={this.props.account.linkedin_url ? null : "dull-icon"} target="_blank" rel="noopener noreferrer" href={`//${this.state.linkedIn}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={this.props.account.linkedin_url ? "black" : "rgb(132, 132, 132)"} className="bi bi-linkedin" viewBox="0 0 16 16" id="account-socials">
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                            </svg>
                            <p className="mobile-tab">LinkedIn</p>
                        </a>
                        <a className="nav-link tab-link d-flex" id={this.props.account.website_url ? null : "dull-icon"} target="_blank" rel="noopener noreferrer" href={`//${this.state.website}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={this.props.account.website_url ? "black" : "rgb(132, 132, 132)"} className="bi bi-link-45deg" viewBox="0 0 16 16" id="account-socials">
                                <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                                <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
                            </svg>
                            <p className="mobile-tab">Personal Site</p>
                        </a>
                    </div>
                </div>
                <div className="container d-flex">
                    <AccountBio
                        account={this.props.account}
                        accountId={this.state.accountId}
                        paramsId={this.props.id}
                        accountsHostUrl={this.props.accountsHostUrl}
                    />
                </div>
                <div className="container">
                    <AccountProjects
                        account={this.props.account}
                        accountId={this.state.accountId}
                        currentUsersAccount={this.state.currentUsersAccount}
                        name={`${this.props.account.user.first_name} ${this.props.account.user.last_name}`}
                        userHasFollowed={this.state.userHasFollowed}
                        updateUserHasFollowed={() => this.updateUserHasFollowed()}
                        followClickCount={this.state.followClickCount}
                        accountsHostUrl={this.props.accountsHostUrl}
                        projectsHostUrl={this.props.projectsHostUrl}
                        reactHostUrl={this.props.reactHostUrl}
                    />
                </div>
            </>
            : null
            }
            </>
        );
    }
}


export default AccountProfile;