import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../Authentication/Axios';
import Modal from 'react-bootstrap/Modal';
import NavProfileDropdown from './NavProfileDropdown';
import Login from '../Authentication/Login';
import Signup from '../Authentication/Signup';


function NavCta(props) {
    const [accountId, setAccountId] = useState(0);
    const [account, setAccount] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [showSignupPopup, setShowSignupPopup] = useState(false);
    const [finishedLoading, setFinishedLoading] = useState(false);
    const imageBaseUrl = "https://coding-boot-champ-assets.s3.amazonaws.com/media/";
    

    useEffect(() => {
        const id = localStorage.getItem('id');
        setAccountId(id);
        const token = localStorage.getItem('access_token');
        if (token == null) {
            setIsAuthenticated(false);
        } else {
            setIsAuthenticated(true);
            const fetchData = async () => {
                try {
                    const response = await axiosInstance.get(`${props.accountsHostUrl}/api/accounts/${id}/`);
                    const data = response.data.account;
                    setAccount(data);
                } catch (error) {
                    console.log("There was an error loading account", error);
                }
            }
            fetchData();
        }
        setTimeout(() => {
            setFinishedLoading(true)
        }, 200);
    }, [props.accountsHostUrl]);


    const toggleLoginPopup = () => {
        setShowLoginPopup(!showLoginPopup)
    }

    const toggleSignupPopup = () => {
        setShowSignupPopup(!showSignupPopup)
    }


    return (
        <>
        <Modal show={showLoginPopup} onHide={toggleLoginPopup}>
            <div className="modal-styling">
                <Modal.Header closeButton>
                    <Modal.Title className="auth-header">Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Login />
                </Modal.Body>
            </div>
        </Modal>
        <Modal show={showSignupPopup} onHide={toggleSignupPopup}>
            <div className="modal-styling">
                <Modal.Header closeButton>
                    <Modal.Title className="auth-header">Signup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Signup />
                </Modal.Body>
            </div>
        </Modal>
        {finishedLoading
        ? <>
            {isAuthenticated
            ? <div className="nav-auth d-flex">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item dropdown">
                        <Link className="nav-link" id="nav-link" data-bs-toggle="dropdown" aria-current="page" to="/null/">
                            {account.profile_picture
                                ? <img className="profile-icon-mini" src={`${imageBaseUrl}${account.profile_picture}`} alt="profile"/>
                                : <svg xmlns="http://www.w3.org/2000/svg" fill="#b4b4b3" className="bi bi-person-circle profile-icon-mini" viewBox="0 0 16 16">
                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                </svg>
                            }
                        </Link>
                        <NavProfileDropdown id={accountId} />
                    </li>
                </ul>
            </div>
            : <div className="nav-auth d-flex">
                <Link className="nav-login" id="nav-link" onClick={toggleLoginPopup}>Login</Link>
                <Link className="btn btn-primary nav-signup" onClick={toggleSignupPopup}>Signup</Link>
            </div>}
        </>
        : null
        }
        </>
    );
}


export default NavCta;