import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


function AccountFollowFromProject(props) {
    const [finishedLoading, setFinishedLoading] = useState(false);


    useEffect(() => {
        setTimeout(() => {
            setFinishedLoading(true);
        }, 300);
    }, []);


    return (
        <>
            {finishedLoading
                ? <>
                    <div className="follow-button-div">
                        {props.currentUsersAccount 
                            ? <Link type="button" className="btn btn-primary project-follow-button" to={"create/"}>
                                Edit Profile
                            </Link> 
                            : <button onClick={props.updateUserHasFollowed()} type="button" className="btn btn-primary project-follow-button" id={props.followClickCount >= 2 ? "disable-follow" : null}>
                                {props.userHasFollowed ? "Following" : "Follow"}
                            </button>
                        }
                    </div>
                </>
                : null
            }
        </>
    )
}


export default AccountFollowFromProject;