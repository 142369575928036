import { BrowserRouter } from 'react-router-dom';
import Nav from '../Navigation/Nav';
import AnimatedRoutes from './AnimatedRoutes';
import Footer from './Footer';


function App() {
  const domain = /https?:\/\/[^/]+/;
  let reactHost = "http://localhost:3000";
  if (process.env.hasOwnProperty('REACT_APP_REACT_HOST')) {
      reactHost = process.env.REACT_APP_REACT_HOST;
  }
  const basename = reactHost.replace(domain, '');


  return (
    <BrowserRouter basename={basename} >
      <Nav />
      <AnimatedRoutes />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
