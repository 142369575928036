import React from 'react';
import Modal from 'react-bootstrap/Modal';
import BootcampProjects from '../Bootcamp/BootcampProjects';
import AccountFollowFromProject from './AccountFollowFromProject';
import ProjectCreate from '../Project/ProjectCreate';


class AccountProjects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allProjects: [],
            discoverableProjects: [],
            createProjectPopupIsOpen: false,
            finishedLoading: false,
            account: {},
        }
    }


    async componentDidMount() {
        const response = await fetch(`${this.props.projectsHostUrl}/api/projects/`);
        if (response.ok) {
            const data = await response.json();
            const allProjectsList = [];
            data.projects.forEach(project => {
                if (project.team_members.some(account => account.id === this.props.account.id)) {
                    allProjectsList.push(project);
                }

                const discoverableProjects = [];
                allProjectsList.forEach(project => {
                    if (project.discoverable === true) {
                        discoverableProjects.push(project);
                    }
                });

                this.setState({
                    allProjects: allProjectsList,
                    discoverableProjects: discoverableProjects,
                });
            });
            setTimeout(() => {
                this.setState({
                    finishedLoading: true,
                    account: this.props.account,
                })
            }, 200);
        } else {
            console.log("There was an error fetching account's projects: ", response);
        }
    }


    toggleCreateProjectPopup = () => {
        this.setState({
            createProjectPopupIsOpen: (!this.state.createProjectPopupIsOpen),
        })
    }
    
    
    render() {
        return(
            <>
            <Modal show={this.state.createProjectPopupIsOpen} onHide={this.toggleCreateProjectPopup}>
                <div className="modal-styling">
                    <Modal.Header closeButton>
                        <Modal.Title className="auth-header">Create a Project</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ProjectCreate account={this.state.account} />
                    </Modal.Body>
                </div>
            </Modal>
            {this.props.currentUsersAccount
                ? <> 
                    {this.state.allProjects.length > 0
                        ? <div id="tags-container">
                            <h2 className="sub-title" id="indent">My Projects</h2>
                            <BootcampProjects
                                type="account"
                                displayFilters={false}
                                accountProjects={this.state.allProjects}
                                currentUsersAccount={this.props.currentUsersAccount}
                                projectsHostUrl={this.props.projectsHostUrl}
                                reactHostUrl={this.props.reactHostUrl}
                            />
                        </div>
                        : <div className="sub-container-no-border">
                            {this.state.finishedLoading
                                ? <div className="shadow-sm" id="account-cta-div">
                                    <p className="account-cta">Join our community of builders showcasing their final projects.</p>
                                    <button type="button" className="btn btn-primary account-cta-button" onClick={() => this.toggleCreateProjectPopup()}>
                                        Add Your Project
                                    </button>
                                </div>
                                : null
                            }
                        </div>
                    }
                </>
                : <>
                    {this.state.discoverableProjects.length > 0
                        ? <div id="tags-container">
                            <h2 className="sub-title" id="indent">My Projects</h2>
                            <BootcampProjects
                                type="account"
                                displayFilters={false}
                                accountProjects={this.state.discoverableProjects}
                                currentUsersAccount={this.props.currentUsersAccount}
                                projectsHostUrl={this.props.projectsHostUrl}
                                reactHostUrl={this.props.reactHostUrl}
                            />
                        </div> 
                        : <div className="sub-container-no-border">
                            {this.state.finishedLoading
                                ? <div className="shadow-sm" id="account-cta-div">
                                    <p className="account-cta">{this.props.name} hasn't launched a project yet. Follow them to get notified when they do.</p>
                                    <AccountFollowFromProject
                                        accountId={this.props.accountId}
                                        currentUsersAccount={this.props.currentUsersAccount}
                                        userHasFollowed={this.props.userHasFollowed}
                                        updateUserHasFollowed={() => this.props.updateUserHasFollowed()}
                                        followClickCount={this.props.followClickCount}
                                    />
                                </div>
                                : null
                            }
                        </div>
                    }
                </>
                }
            </>
        );
    }
}


export default AccountProjects;