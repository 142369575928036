import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProjectProfile from './ProjectProfile';


function ProjectMain() {
    const { id } = useParams();

    const [project, setProject] = useState("");


    let accountsHost = "http://localhost:8100";
    if (process.env.hasOwnProperty('REACT_APP_ACCOUNTS_SERVICE_HOST')) {
        accountsHost = process.env.REACT_APP_ACCOUNTS_SERVICE_HOST;
    }


    let projectsHost = "http://localhost:8090";
    if (process.env.hasOwnProperty('REACT_APP_PROJECTS_SERVICE_HOST')) {
        projectsHost = process.env.REACT_APP_PROJECTS_SERVICE_HOST;
    }


    let reactHost = "http://localhost:3000";
    if (process.env.hasOwnProperty('REACT_APP_REACT_HOST')) {
        reactHost = process.env.REACT_APP_REACT_HOST;
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response =  await fetch(`${projectsHost}/api/projects/${id}/`)
                const data = await response.json();
                setProject(data.projects);
            } catch (error) {
                console.log("There was an error loading project", error)
            }
        }
        fetchData();
    }, [id, projectsHost]);

    
    if (project === "") {
        return null;
    } else {
        return (
            <div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <ProjectProfile
                    project={project}
                    id={id}
                    accountsHostUrl={accountsHost}
                    projectsHostUrl={projectsHost}
                    reactHostUrl={reactHost}
                />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        );
    }
}


export default ProjectMain;