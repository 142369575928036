import React from 'react';
import { Link } from 'react-router-dom';
import BootcampMetrics from './BootcampMetrics';
import BootcampProjects from './BootcampProjects';
import BootcampAlumni from './BootcampAlumni';
import BootcampAbout from './BootcampAbout';


class BootcampProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            programs: [],
            programTypes: [],
            websiteUrl: "",
            projectsAreShowing: true,
            alumniAreShowing: false,
            aboutIsShowing: false,
            finishedLoading: false,
        }
    }


    async componentDidMount() {
        const response = await fetch(`${this.props.bootcampsHostUrl}/api/programs/`);
        if (response.ok) {
            const data = await response.json();
            const filteredPrograms = [];
            const filteredProgramTypes = [];
            for (let program of data.programs) {
                if (program.bootcamp.id === this.props.bootcamp.id) {
                    filteredPrograms.push(program);
                }
            }
            for (let program of filteredPrograms) {
                if (!(filteredProgramTypes.includes(program.type.name))) {
                    filteredProgramTypes.push(program.type.name);
                }
            }
            this.setState({
                programs: filteredPrograms,
                programTypes: filteredProgramTypes,
            });
        } else {
            console.log("There was an error fetching list of programs: ", response);
        }

        const extension = /^https?:\/\//;
        setTimeout(() => {
            this.setState({
                websiteUrl: this.props.bootcamp.website_url.replace(extension, ""),
                finishedLoading: true,
            })
        }, 200);
    }


    toggleProjectsTab = () => {
        this.setState({
            projectsAreShowing: true,
            alumniAreShowing: false,
            aboutIsShowing: false,
        });
    }


    toggleAlumniTab = () => {
        this.setState({
            projectsAreShowing: false,
            alumniAreShowing: true,
            aboutIsShowing: false,
        });
    }


    toggleAboutTab = () => {
        this.setState({
            projectsAreShowing: false,
            alumniAreShowing: false,
            aboutIsShowing: true,
        });
    }

    
    render() {
        return(
            <>
                {this.state.finishedLoading
                    ? <>
                    <div className="container d-flex">
                        <div className="p-2">
                            <div>
                                <img className="logo" src={this.props.bootcamp.logo} alt={`${this.props.bootcamp.name} logo`} />
                            </div>
                            <div className="profile-info">
                                <h2 className="header-title">{this.props.bootcamp.name}</h2>
                                <p className="sub-title">{this.props.bootcamp.tagline}</p>
                                {/* {this.state.programTypes.map(type => {
                                    return <div className="profile-chip" id="bootcamp-chip" key={type}>
                                        <p>{type}</p>
                                    </div>
                                })} */}
                                <div className="profile-chip" id="bootcamp-chip">
                                    <p>Software Engineering</p>
                                </div>
                            </div>
                            <BootcampMetrics
                                bootcamp={this.props.bootcamp}
                                accountsHostUrl={this.props.accountsHostUrl}
                                projectsHostUrl={this.props.projectsHostUrl}
                            />
                        </div>
                    </div>
                    <div className="container d-flex" id="container-tabs">
                        <div className="tab-link-div d-flex">
                            <Link onClick={() => this.toggleProjectsTab()} className="nav-link tab-link" to="#">Projects</Link>
                            <Link onClick={() => this.toggleAlumniTab()} className="nav-link tab-link" to="#">Alumni</Link>
                            <Link onClick={() => this.toggleAboutTab()} className="nav-link tab-link" to="#">About</Link>
                            <div className="tab-link-end-div">
                                <a className="nav-link tab-link-end" target="_blank" rel="noopener noreferrer" href={`//${this.state.websiteUrl}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="black" className="bi-link-45deg bootcamp-website" viewBox="0 0 16 16">
                                        <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                                        <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
                                    </svg>
                                    {this.state.websiteUrl}
                                </a>
                                <a className="nav-link tab-link-end" id="mobile-link" target="_blank" rel="noopener noreferrer" href={`//${this.state.websiteUrl}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="black" className="bi-link-45deg bootcamp-website" viewBox="0 0 16 16">
                                        <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                                        <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        {this.state.projectsAreShowing && <BootcampProjects
                                                                type="bootcamp"
                                                                bootcamp={this.props.bootcamp}
                                                                displayFilters={true}
                                                                projectsHostUrl={this.props.projectsHostUrl}
                                                                reactHostUrl={this.props.reactHostUrl}
                                                            />
                        }
                        {this.state.alumniAreShowing && <BootcampAlumni
                                                            bootcamp={this.props.bootcamp}
                                                            teamMembers="null"
                                                            accountsHostUrl={this.props.accountsHostUrl}
                                                            reactHostUrl={this.props.reactHostUrl}
                                                            />
                        }
                        {this.state.aboutIsShowing && <BootcampAbout
                                                            bootcamp={this.props.bootcamp}
                                                            bootcampsHostUrl={this.props.bootcampsHostUrl}
                                                        />
                        }
                    </div>
                    </>
                    : null
                }
            </>
        );
    }
}


export default BootcampProfile;