import { React } from 'react';
import { Link } from 'react-router-dom';


function AccountNotification() {
    return (
        <div className="container">
            <div className="alert alert-primary normal-alert shadow-sm" role="alert">
                <strong>Your Profile Is Incomplete:</strong> Add a profile picture and headline to increase your visibility on the platform.  <Link to={"create/"} className="alert-link" id="alert-link">Complete My Profile</Link>
            </div>
        </div>
    );
}


export default AccountNotification;