import React from 'react';
import { Link } from 'react-router-dom';


class NavBootcampDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bootcamps: [],
        }
    }


    async componentDidMount() {
        const response = await fetch(`${this.props.bootcampsHostUrl}/api/bootcamps/`);
        if (response.ok) {
            const data = await response.json();
            this.setState({
                bootcamps: data.bootcamps,
            })
        } else {
            console.log("There was an error fetching list of bootcamps: ", response);
        }
    }


    render() {
        return(
            <div className="dropdown-menu dropdown-menu-start">
                {this.state.bootcamps.map(bootcamp => {
                    return ( 
                        <Link key={bootcamp.id} to={`/bootcamps/${bootcamp.id}/`} className="navlink dropdown-item">{bootcamp.name}</Link>
                    )
                })}
            </div>
        );
    }
}


export default NavBootcampDropdown;