import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Carousel } from 'react-responsive-carousel';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';


class ProjectCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            finishedLoading: false,
            imageBaseUrl: "https://coding-boot-champ-assets.s3.amazonaws.com/media/",
            popupIsOpen: false,
            styles: styles,
        }
    }


    async componentDidMount() {
        setTimeout(() => {
            this.setState({
                finishedLoading: true
            })
        }, 200);
    }


    togglePopup = () => {
        this.setState({
            popupIsOpen: (!this.state.popupIsOpen),
        });
    }
    
    
    render() {
        return(
            <>
            <Modal show={this.state.popupIsOpen} onHide={this.togglePopup} size="xl">
                <div className="modal-styling">
                    <Modal.Header closeButton>
                        <Modal.Title className="auth-header">Screenshots</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Carousel>
                            {this.props.images.map(image => {
                                return (
                                    <div key={image.id}>
                                        <img className="popup-image" src={`${this.state.imageBaseUrl}${image.image.replace('/app/media/', '')}`} alt={`screenshot of ${this.props.project.title}`}/>
                                    </div>
                                );
                            })}
                        </Carousel>
                    </Modal.Body>
                </div>
            </Modal>
            {this.state.finishedLoading
                ? <div className="sub-container">
                    <div className="containter-fluid">
                        <div className="row">
                            <div className="col-sm">
                                <h2 className="sub-title">Screenshots</h2>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-container">
                        <div className="row">
                            <div className="col-9 main-image-div">
                                <img className="main-image" src={`${this.state.imageBaseUrl}${this.props.mainImage.replace('/app/media/', '')}`} alt={`screenshot of ${this.props.project.title}`} onClick={() => this.togglePopup()}/>
                            </div>
                            <div className="col">
                                <div className="vertical-carousel">
                                    {this.props.secondaryImages.map(image => {
                                        return (
                                            <div key={image.id} className="thumbnails-div">
                                                <img className="thumbnails" src={`${this.state.imageBaseUrl}${image.image.replace('/app/media/', '')}`} alt={`screenshot of ${this.props.project.title}`} onClick={() => this.togglePopup()}/>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null
            }
            </>
        );
    }
}


export default ProjectCarousel;