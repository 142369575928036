import React, { useEffect, useState } from 'react';


function BootcampAlumniFollow(props) {
    const [currentUserId, ] = useState(localStorage.getItem('id'));
    const [followers, setFollowers] = useState([]);
    const [userHasFollowed, setUserHasFollowed] = useState(false);
    const [followClickCount, setFollowClickCount] = useState(0);
    const [finishedLoading, setFinishedLoading] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const followersResponse =  await fetch(`${props.accountsHostUrl}/api/followers/${props.accountId}/`)
                const followerData = await followersResponse.json();
                const followerArray = [];
                followerData.followers.forEach(follower => {
                    followerArray.push(follower.follower.id);
                });
                if (followerArray.includes(parseInt(currentUserId))) {
                    setFollowers(followerData.followers);
                    setUserHasFollowed(true);
                }
            } catch (error) {
                console.log("There was an error loading followers", error)
            }
        }
        fetchData();
        setTimeout(() => {
            setFinishedLoading(true);
        }, 200);
    }, [
        currentUserId,
        props.accountId,
        props.accountsHostUrl,
    ]);


    const updateUserHasFollowed = () => {
        return async event => {
            if (!(userHasFollowed)) {
                event.preventDefault();
                const data = {
                    "followee": props.accountId,
                    "follower": currentUserId,
                }
                const response = await fetch (`${props.accountsHostUrl}/api/follows/`, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    setUserHasFollowed(true);
                    setFollowClickCount(followClickCount + 1);
                }
            } else {
                event.preventDefault();
                let followId = 0;
                followers.forEach(follower => {
                    if (follower.follower.id === parseInt(currentUserId)) {
                        followId = follower.id;
                    }
                });
                const response = await fetch (`${props.accountsHostUrl}/api/follows/${followId}/`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    setUserHasFollowed(false);
                    setFollowClickCount(followClickCount + 1);
                }
            }
        }
    }


    return (
        <>
            {finishedLoading
                ? <>
                    {!(props.accountId === parseInt(currentUserId))
                        ? <button 
                            onClick={updateUserHasFollowed()}
                            type="button"
                            className={props.mobile ? "btn btn-primary follow-button-mobile" : "btn btn-primary"}
                            id={props.mobile ? null : "account-follow"}
                        >
                            {userHasFollowed ? "Following" : "Follow"}
                        </button>
                        : null
                    } 
                </>
                : null
            }
        </>
    )
}


export default BootcampAlumniFollow;