import { React, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axiosInstance from './Axios';


function Logout() {
	const navigate = useNavigate();


	let accountsHost = "http://localhost:8100";
    if (process.env.hasOwnProperty('REACT_APP_ACCOUNTS_SERVICE_HOST')) {
        accountsHost = process.env.REACT_APP_ACCOUNTS_SERVICE_HOST;
    }


	useEffect(() => {
		axiosInstance.post(`${accountsHost}/api/user/logout/blacklist/`, {
			refresh_token: localStorage.getItem('refresh_token'),
		});
		localStorage.clear();
		axiosInstance.defaults.headers['Authorization'] = null;
		setTimeout(() => {
            navigate('/');
			window.location.reload(false);
        }, 100);
	});


	return <div>Logout</div>;
}


export default Logout;