import { React } from 'react';
import { useParams } from 'react-router-dom';
import AccountForm from './AccountForm';


function AccountPreForm() {
    const { id } = useParams();

    return (
        <AccountForm useParamsAccountId={id} />
    );
}


export default AccountPreForm;