import { React } from 'react';
import { useParams } from 'react-router-dom';
import ProjectForm from './ProjectForm';


function ProjectPreForm() {
    const { id } = useParams();

    return (
        <ProjectForm projectId={id} />
    );
}


export default ProjectPreForm;