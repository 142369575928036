import React from 'react';
import axiosInstance from '../Authentication/Axios';


class ProjectElevatorPitch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortedTags: [],
            show: false,
        }
    }
    

    componentDidMount() {
        setTimeout(() => {
            axiosInstance.get(`${this.props.projectsHostUrl}/api/projects/tags/`)
            .then(response => {
                const projectTagsData = response.data.project_tags;
                
                const filteredTags = [];
                projectTagsData.forEach(tag => {
                    if (tag.project.id === parseInt(this.props.paramsId)) {
                        filteredTags.push(tag);
                    }
                });
                
                const sortedTags = filteredTags.sort((a, b) => (a.order > b.order) ? 1 : -1);
                
                this.setState({
                    sortedTags: sortedTags,
                    show: true,
                });
            })
            .catch(error => console.log("projectTags: ", error))
        }, 200);
    }


    render() {
        return(
            <>
            {this.state.show && (this.state.sortedTags.length > 0 || this.props.project.elevator_pitch)
                ?
                <div className="sub-container" id="tags-container">
                    {this.state.sortedTags.length > 0
                        ?
                        <>
                            {this.state.show ? <div className="tags">
                                {this.state.sortedTags.map(tag => {
                                    return <div key={tag.tag.id} className="profile-chip" id="tag-chip">
                                        <p>{tag.tag.name}</p>
                                    </div>
                                })}
                            </div> : null}
                        </>
                        : null
                    }
                    {this.props.project.elevator_pitch
                        ?
                        <>
                            <div className="containter-fluid">
                                <div className="row">
                                    <div className="col-sm">
                                        <h2 className="sub-title" id="bio-title">Elevator Pitch</h2>
                                    </div>
                                </div>
                            </div>
                            <p className="p-font" id="bio">{this.props.project.elevator_pitch}</p>
                        </>
                        : null
                    }
                </div>
                : null
            }
            </>
        );
    }
}


export default ProjectElevatorPitch;